<!-- Wrapper div to ensure the autocomplete dropdown is aligned with the search input and button -->

<div
  class="d-flex flex-column flex-grow-1 position-relative"
  [ngClass]="{ 'width-of-search-bar-results': !this.commonService.isHome() }"
>
  <app-sde-search-form
    [query]="searchService.query"
    #searchForm
    [searchRoute]="searchRoute"
    [showFilterCount]="true"
    [autoSubmit]="true"
  >
    <!-- The search form won't auto-submit on "Enter", instead the autocomplete captures this event and acts accordingly -->

    <ng-template let-query>
      <app-autocomplete
        [inputElement]="searchForm.searchInput.nativeElement"
        [queryText]="query.text || ''"
        [suggestTypes]="autocompleteSources"
        [suggestQuery]="
          appService.suggestQueries ? appService.suggestQueries[0] : ''
        "
        (search)="onAutocompleteSearch($event, query)"
        (select)="onAutocompleteSelect($event, query)"
      >
      </app-autocomplete>
    </ng-template>
  </app-sde-search-form>
</div>
