<div
  class="home contact-us-page-background"
  [ngClass]="{
    'contact-us-page-background-ej': source == 'EJ'
  }"
>
  <div>
    <app-sde-common-header></app-sde-common-header>
  </div>

  <div
    #contactUsContainer
    [ngClass]="{
      'ej-background col-11 col-sm-11 col-lg-11 flex-row justify-content-start':
        source == 'EJ',
      'd-flex px-20 form-align-mobile-view flex-column justify-content-center align-items-center':
        source == 'SDE' || source == 'TDAMM'
    }"
    [ngStyle]="source === 'EJ' ? setContainerHeight(contactUsContainer) : ''"
    *ngIf="!contactUsFormSubmitted"
  >
    <div class="row main-container">
      <div
        (keyup.enter)="goBack()"
        tabindex="0"
        role="button"
        class="back-to-home"
        style="margin-left: 13px"
        (click)="goBack()"
      >
        <img src="assets/img/back-arrow.svg" alt="back arrow" />
        <span>{{ "Back" | uppercase }}</span>
      </div>

      <div
        class="col-sm-12 col-md-11 col-xl-11 contact-us-content d-flex flex-column justify-content-center align-items-start form-align-mobile-view"
      >
        <div
          class="contact-us-text"
          [ngClass]="{
            'font-family-rajdhani-semibold': source == 'EJ',
            'font-family-rajdhani-regular': source == 'SDE' || source == 'TDAMM'
          }"
        >
          Contact Us
        </div>

        <div class="req-inf-text-contact-us">* = required information</div>
        <ng-container
          *ngIf="source === 'EJ' && !ui.screenSizeIsLessOrEqual('xs')"
        >
          <p class="ej-note font-family-rajdhani-semibold">
            Note :
            <span class="ej-note-content font-family-source-sans-pro-regular"
              >This is the Beta version of the NASA Environmental Justice Data
              Search Interface (powered by the Science Discovery Engine). We are
              continually working on enhancing the content, features, and
              functionality of the interface.</span
            >
          </p>
        </ng-container>
        <form
          [formGroup]="contactUsForm"
          (ngSubmit)="onSubmitContactUsForm()"
          [ngClass]="{ 'width-70': source == 'EJ' }"
          class="d-flex flex-column"
        >
          <div class="row">
            <div class="form-group col-12 col-sm-12 col-lg-6">
              <div
                class="mbvh-1"
                [ngClass]="{
                  'mobile-label-container': ui.screenSizeIsLessOrEqual('xs'),
                  'label-container': ui.screenSizeIsGreater('sm')
                }"
              >
                <div>
                  <p
                    class="font-family-rajdhani-medium"
                    [ngClass]="{
                      'field-name': source === 'SDE' || source == 'TDAMM',
                      'ej-field-name': source === 'EJ'
                    }"
                  >
                    Name*&nbsp;(First and Last)
                  </p>
                </div>

                <div
                  *ngIf="f.firstName.touched && f.firstName.errors"
                  class="validation-message"
                >
                  <div *ngIf="f.firstName.errors.required">
                    (This is a mandatory field)
                  </div>
                  <div>
                    <span *ngIf="f.firstName.errors.pattern">
                      (Name can only contain letters and spaces)</span
                    >
                    <span *ngIf="f.firstName.errors.htmlError">
                      (HTML content is not allowed)</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <input
                  placeholder="Name"
                  type="text"
                  aria-required="true"
                  formControlName="firstName"
                  class="form-control input-field"
                  [ngClass]="{
                    'is-invalid': f.firstName.touched && f.firstName.errors,
                    'input-field-ej': source == 'EJ'
                  }"
                />
              </div>
            </div>

            <div class="form-group col-12 col-sm-12 col-lg-6 mt-3 mt-lg-0">
              <div
                class="mbvh-1"
                [ngClass]="{
                  'mobile-label-container': ui.screenSizeIsLessOrEqual('xs'),
                  'label-container': ui.screenSizeIsGreater('sm')
                }"
              >
                <p
                  class="font-family-rajdhani-medium"
                  [ngClass]="{
                    'field-name': source === 'SDE' || source === 'TDAMM',
                    'ej-field-name': source === 'EJ'
                  }"
                >
                  Email*
                </p>

                <div
                  *ngIf="f.email.touched && f.email.errors"
                  class="validation-message"
                >
                  <div *ngIf="f.email.errors.required">
                    (This is a mandatory field)
                  </div>
                  <div>
                    <span *ngIf="f.email.errors.email">
                      (Enter a valid email address)</span
                    >
                    <span *ngIf="f.email.errors.htmlError">
                      (HTML content is not allowed)</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <input
                  placeholder="Email"
                  aria-required="true"
                  type="text"
                  formControlName="email"
                  class="form-control input-field"
                  [ngClass]="{
                    'is-invalid': f.email.touched && f.email.errors,
                    'input-field-ej': source == 'EJ'
                  }"
                />
              </div>
            </div>
          </div>

          <div class="sourcelink-top-div">
            <div
              [ngClass]="{
                'subject-main-div': source === 'SDE' || source === 'TDAMM',
                'ej-subject-main-div': source === 'EJ'
              }"
            >
              <div
                class="mbvh-1"
                [ngClass]="{
                  'mobile-label-container': ui.screenSizeIsLessOrEqual('xs'),
                  'label-container': ui.screenSizeIsGreater('sm')
                }"
              >
                <p
                  class="font-family-rajdhani-medium"
                  [ngClass]="{
                    'field-name': source === 'SDE' || source === 'TDAMM',
                    'ej-field-name': source === 'EJ'
                  }"
                >
                  Subject*
                </p>

                <div
                  *ngIf="f.subject.touched && f.subject.errors"
                  class="validation-message"
                >
                  <div *ngIf="f.subject.errors.required">
                    (This is a mandatory field)
                  </div>
                  <div>
                    <span *ngIf="f.subject.errors.maxlength">
                      (Subject must be less than 100 characters)</span
                    >
                    <span *ngIf="f.subject.errors.htmlError">
                      (HTML content is not allowed)</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <input
                  placeholder="Add your subject here"
                  aria-required="true"
                  type="text"
                  formControlName="subject"
                  class="form-control input-field-subject"
                  [ngClass]="{
                    'is-invalid': f.subject.touched && f.subject.errors,
                    'input-field-ej': source == 'EJ'
                  }"
                />
              </div>
            </div>
            <div
              [ngClass]="{
                'comments-main-div': source === 'SDE' || source === 'TDAMM',
                'ej-comments-main-div': source === 'EJ'
              }"
            >
              <div
                class="mbvh-1"
                [ngClass]="{
                  'mobile-label-container': ui.screenSizeIsLessOrEqual('xs'),
                  'label-container': ui.screenSizeIsGreater('sm')
                }"
              >
                <p
                  class="font-family-rajdhani-medium"
                  [ngClass]="{
                    'field-name': source === 'SDE' || source === 'TDAMM',
                    'ej-field-name': source === 'EJ'
                  }"
                >
                  Comments/Questions*
                </p>
                <div
                  *ngIf="f.comments.touched && f.comments.errors"
                  class="validation-message"
                >
                  <div *ngIf="f.comments.errors.required">
                    (This is a mandatory field)
                  </div>
                  <div>
                    <span *ngIf="f.comments.errors.maxlength">
                      (Comments must be less than 500 characters)</span
                    >
                    <span *ngIf="f.comments.errors.htmlError">
                      (HTML content is not allowed)</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <input
                  placeholder="We would love to hear from you."
                  type="text"
                  aria-required="true"
                  formControlName="comments"
                  class="form-control input-field-subject"
                  [ngClass]="{
                    'is-invalid': f.comments.touched && f.comments.errors,
                    'input-field-ej': source == 'EJ'
                  }"
                />
              </div>
            </div>
          </div>
          <div
            class="submit-button-div"
            [ngClass]="{
              'submit-button-div-ej ': ui.screenSizeIsLessOrEqual('xs')
            }"
          >
            <button
              class="submit-button"
              aria-label="Submit your inquiry or request to contact the SDE team."
              [ngClass]="{
                'submit-button-ej font-family-source-sans-pro-semibold':
                  source == 'EJ',
                'font-family-rajdhani-regular':
                  source == 'SDE' || source === 'TDAMM'
              }"
            >
              <ng-container *ngIf="source === 'SDE' || source === 'TDAMM'">
                {{ "Submit" | uppercase }}
              </ng-container>
              <ng-container *ngIf="source === 'EJ'">
                {{ "Submit" | titlecase }}
              </ng-container>
            </button>
          </div>
          <ng-container
            *ngIf="source === 'EJ' && ui.screenSizeIsLessOrEqual('xs')"
          >
            <p class="ej-note-mobile font-family-rajdhani-semibold">
              Note :
              <span
                class="ej-note-content-mobile font-family-source-sans-pro-regular"
                >This is the Beta version of the NASA Environmental Justice Data
                Search Interface (powered by the Science Discovery Engine). We
                are continually working on enhancing the content, features, and
                functionality of the interface.</span
              >
            </p>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
  <div
    class="thank-you-container"
    *ngIf="contactUsFormSubmitted"
    [ngClass]="{ 'ej-thank-you': source == 'EJ' }"
  >
    <div class="thankyou-text font-family-rajdhani-semibold">Thank You</div>
    <div class="thankyou-textcontent font-family-rajdhani-medium">
      We have received your inquiry, and we will be in touch shortly. We thank
      you for your patience.
    </div>
    <div
      *ngIf="source === 'EJ'"
      class="ej-thank-you-back-button"
      (click)="goBackToEjHome()"
    >
      <p
        class="ej-thank-you-back-button-content pt-1 font-family-source-sans-pro-semibold"
      >
        Back to Environmental Justice Homepage
      </p>
    </div>
    <div
      *ngIf="source === 'SDE' || source === 'TDAMM'"
      class="sde-thank-you-back-button"
      tabindex="0"
      role="button"
      (keyup.enter)="goBackToSDEHome()"
      (click)="goBackToSDEHome()"
    >
      <p
        class="sde-thank-you-back-button-content pt-1 font-family-source-sans-pro-semibold"
      >
        Back to SDE Homepage
      </p>
    </div>
  </div>
</div>
