import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  NotificationsService,
  NotificationType,
} from "@sinequa/core/notification";
import { DeviceDetectorService } from "ngx-device-detector";
@Component({
  selector: "app-scix-metadata-viewer",
  templateUrl: "./scix-metadata-viewer.component.html",
  styleUrls: ["./scix-metadata-viewer.component.scss"],
})
export class ScixMetadataViewerComponent implements OnInit {
  @Input() scixMetaData: any;
  @Input() previewType: string = "default";
  expandedText: boolean;

  /**
   * This ViewChild property is used to store DOM Reference of left overview grid container.
   */
  @ViewChild("leftSection") leftContainer: ElementRef;
  @ViewChild("scixPreview") scixPreview: ElementRef;
  @ViewChild("scixdataContent") scixdataContent: ElementRef;

  //  This ViewChild property is used to store DOM Reference of right content.
  @ViewChild("rightSectionContent") rightContent: ElementRef;

  //  This ViewChild property is used to store DOM Reference of right overview grid container.
  @ViewChild("rightSection") rightContainer: ElementRef;
  isCollapsed = true;
  showReadMoreButton = false;
  @ViewChild("rightSectionContent") rightSectionContent: ElementRef;

  /**
   * This ViewChild property is used to store DOM Reference of right content title.
   */
  @ViewChild("rightSectionTitle") rightTitle: ElementRef;

  //**emitting this to close the ScixMetadataViewer to Search component where we have the selector app-scix-metadata-viewer **//
  @Output() close: EventEmitter<void> = new EventEmitter<void>(); // Define close event
  scixSourceUrl: string;
  showAllAuthors: boolean = false;
  showEllipsis: boolean = true;

  constructor(
    private notificationsService: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.setScixPreviewStyle();
  }

  setScixPreviewStyle() {
    this.changeDetectorRef.detectChanges();
    let top = this.scixPreview.nativeElement.getBoundingClientRect().top;
    this.scixPreview.nativeElement.style.height =
      window.innerHeight - top + "px";
  }
  /**
   * When application view is finished initialization, overview grid container heights are adjusted by initialOverviewGridHeight() method.
   */
  ngAfterViewInit(): void {
    this.setScixMetadataContentStyle();
    if (this.rightSectionContent.nativeElement.scrollHeight > 224) {
      this.showReadMoreButton = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scixMetaData) {
      this.changeDetectorRef.detectChanges();
      this.isCollapsed = true;
      var descriptionDiv = document.getElementById(
        "rightSection-id"
      ) as HTMLElement;
      descriptionDiv.scrollTop = 0;

      var sciXMetadataDiv = document.getElementById(
        "scixPreview-id"
      ) as HTMLElement;
      sciXMetadataDiv.scrollTop = 0;
      this.showAllAuthors = false;
    }
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  toggleContent() {
    this.isCollapsed = !this.isCollapsed;
  }

  setScixMetadataContentStyle() {
    let top = this.scixdataContent?.nativeElement?.getBoundingClientRect().top;
    // Calculate the new height by subtracting the 'top' from the window's inner height
    let newHeight = window.innerHeight - top;
    // Set the new height for the metadataContent element
    this.scixdataContent.nativeElement.style.height = `${newHeight}px`;
  }

  openScixSourceLink(bibcode: string): void {
    // Constant parts of the URL
    const baseUrl = "https://scixplorer.org/abs/";
    const endUrl = "/abstract";
    // Construct the full URL
    this.scixSourceUrl = baseUrl + bibcode + endUrl;
    // Open the URL in a new tab
    window.open(this.scixSourceUrl, "_blank");
  }

  copyURLSciX(): void {
    if (!this.scixMetaData || !this.scixMetaData.bibcode) {
      return;
    }

    const baseUrl = "https://scixplorer.org/abs/";
    const endUrl = "/abstract";
    this.scixSourceUrl = baseUrl + this.scixMetaData.bibcode + endUrl;

    // Copy URL to clipboard
    navigator.clipboard
      .writeText(this.scixSourceUrl)
      .then(() => {
        this.notificationsService.notify(
          NotificationType.Success,
          "msg#actionMenu.urlCopiedToClipboard",
          {},
          undefined,
          true
        );
      })
      .catch((error) => {});
  }

  closeScixMetaDataViewer() {
    this.close.emit(); // Emit the close event
  }
}
