import { ChangeDetectorRef, Component, SimpleChanges } from "@angular/core";
import {
  AddFilterOptions,
  FacetService,
} from "../../../../../components/facet/facet.service";
// import { Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { SearchService } from "@sinequa/components/search";
import { Utils } from "@sinequa/core/base/utils";
import {
  AggregationItem,
  isFieldFilter,
  isValueFilter,
  TreeAggregation,
  TreeAggregationNode,
} from "@sinequa/core/web-services";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { environment } from "sde/src/environments/environment";
import { BsFacetList } from "../../../../../components/facet/bootstrap/facet-list/facet-list";
import googleAnalyticsConstants from "../../analytics/google-analytics.constant";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "app-sde-facet-list",
  templateUrl: "./sde-facet-list.component.html",
  styleUrls: ["./sde-facet-list.component.scss"],
})
export class SdeFacetListComponent extends BsFacetList {
  checkboxSelectedFlag: any;
  constructor(
    protected override facetService: FacetService,
    protected override changeDetectorRef: ChangeDetectorRef,
    protected override searchService: SearchService,
    private commonService: CommonService,
    private $gaService: GoogleAnalyticsService,
    private router: Router
  ) {
    super(searchService, facetService, changeDetectorRef);
  }

  listItems: AggregationItem[] | any[] = [];
  tdammFilters;

  trackByMethod(index: number) {
    return index;
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (this.showCount === undefined) this.showCount = true;
    if (this.searchable === undefined) this.searchable = true;
    if (this.allowExclude === undefined) this.allowExclude = true;
    if (this.allowOr === undefined) this.allowOr = true;
    if (this.allowAnd === undefined) this.allowAnd = false;
    this.showCheckbox = this.allowOr || this.allowAnd || this.allowExclude;

    if (changes.results || changes.aggregation) {
      // New data from the search service
      this.data = this.facetService.getAggregation(
        this.aggregation,
        this.results
      );

      if (this.data?.isTree && this.data.items) {
        this.expandItems(this.data.items as TreeAggregationNode[]);
      }
      this.data?.items?.forEach((item) => (item.$selected = false)); // Reinitialize the source aggregation's selected items
      this.selected = [];

      // This code loops through `this.data?.items` and processes filters related to "Messengers", "Objects", or "Signals".
      // It checks if the item matches any of these values, then iterates over the item's nested items.
      // For each nested item, it checks if it matches the currently applied filters in `searchService.query`.
      // Based on the conditions (number of filters and item match), it updates a flag `checkboxSelectedFlag` and selects the item using `selectItem`.
      // If a deeper nested item exists (subFilter), it checks those as well and applies the same logic.
      this.data?.items?.forEach((item: any) => {
        let items;
        let subFilter;
        if (item) {
          this.tdammFilters = item.value;
        }

        if (["Messengers", "Objects", "Signals"].includes(item.value)) {
          item?.items.forEach((e: any) => {
            items = e;
            this.searchService.query.forEachFilter((f) => {
              if (isFieldFilter(f)) {
                if (
                  items.value === f.display &&
                  this.searchService.query.getFilterCount(undefined) === 1 // Single filter scenario
                ) {
                  this.checkboxSelectedFlag = true;
                  this.selectItem(items); // Select the item if the filter matches
                } else if (
                  items.value === f.display &&
                  items?.items &&
                  this.searchService.query.getFilterCount(undefined) > 2 // Multiple filters with nested items
                ) {
                  this.checkboxSelectedFlag = true;
                  this.selectItem(items); // Select the item if multiple filters match
                } else if (items?.items) {
                  items.items.forEach((data) => {
                    subFilter = data;
                    if (subFilter.value === f.display) {
                      this.checkboxSelectedFlag = true;
                      this.selectItem(subFilter); // Selects all the nested filter item
                    }
                  });
                }
              }
            });
          });
        }
      });

      this.clearSearch();
      this.updateItems();
    }
  }

  override updateItems() {
    if (!this.data) {
      this.items = [];
      return;
    }

    if (this.searchable && this.suggests) {
      if (this.data.isTree) {
        this.items = this.facetService.suggestionsToTreeAggregationNodes(
          this.suggests,
          this.searchControl.value,
          this.data
        );
      } else {
        this.items = this.suggests
          .slice(0, this.data.$cccount)
          .map((item) => this.facetService.suggestionToAggregationItem(item));
      }
    } else {
      if (
        this.aggregation === "Messengers_h" ||
        this.aggregation === "Objects_h" ||
        this.aggregation === "Signals_h"
      ) {
        if (this.data.items) {
          this.data?.items.forEach((element: any) => {
            this.items = element?.items;
          });
        }
      } else {
        this.items = this.data.items || [];
      }
    }

    const selected = this.selected.slice();
    const filtered = this.data.$filtered.slice();

    if (this.data.isTree) {
      this.updateTreeItems(filtered, selected);
    } else {
      this.updateListItems(filtered, selected);
    }

    this.changeDetectorRef.detectChanges();
  }

  selectItemModified(item: TreeAggregationNode | AggregationItem, event: any) {
    this.checkboxSelectedFlag = event.target.checked;
    this.selectItem(item);
    if ((<TreeAggregationNode>item).hasChildren) {
      this.open(<TreeAggregationNode>item);
      this.selectChildNodes((<TreeAggregationNode>item).items);
    }
    this.checkParentSelectAll((<TreeAggregationNode>item).$path);
  }

  override open(item: TreeAggregationNode, event?: Event) {
    if (this.data && item.hasChildren) {
      item.$opened = !item.$opened;
      if (!item.items || item.items.length === 0) {
        item.$opening = true;
        const query = this.facetService.getDataQuery(this.results, this.query);
        this.facetService
          .open(this.data as TreeAggregation, item, query, true, this.name)
          .subscribe(() => {
            item.$opening = false;
            this.updateItems();
            if (item.$selected) {
              this.checkboxSelectedFlag = true;
              this.selectChildNodes((<TreeAggregationNode>item).items);
            }
          });
      }
    }
    if (event) event.stopPropagation();
    return false; // Prevent default action
  }

  override selectItem(item: TreeAggregationNode | AggregationItem) {
    if (!item.$filtered) {
      const index = this.selected.findIndex((i) => this.compareItems(item, i));
      if (index === -1 && this.checkboxSelectedFlag) {
        this.selected.push(item);
        item.$selected = true;
      } else if (index !== -1 && !this.checkboxSelectedFlag) {
        this.selected.splice(index, 1);
        delete item.$selected;
      }
      this.updateItems();
    }

    // Filtered items cannot be selected.
    // So, the behavior becomes equivalent to a click on the item.
    // else {
    //   this.filterItem(item);
    // }
  }

  selectChildNodes(items: TreeAggregationNode[]) {
    Utils.traverse(items, (_lineage, node, _level) => {
      this.selectItem(node);
      return false;
    });
  }

  checkParentSelectAll(pathToCheck: string | undefined) {
    if (pathToCheck) {
      let rootItem = (<TreeAggregationNode[]>this.items).find(
        (element) => element.$path === Utils.treeFirst(pathToCheck)
      );
      if (pathToCheck === rootItem?.$path) {
        if (this.isAllSelected(rootItem)) {
          this.checkboxSelectedFlag = true;
        } else {
          this.checkboxSelectedFlag = false;
        }
        this.selectItem(rootItem);
      } else {
        Utils.traverse(
          (<TreeAggregationNode>rootItem).items,
          (_lineage, node, _level) => {
            if (node.$path === pathToCheck) {
              if (this.isAllSelected(node)) {
                this.checkboxSelectedFlag = true;
              } else {
                this.checkboxSelectedFlag = false;
              }
              this.selectItem(node);
              // Recursively traverse each node and check for select all condition
              this.checkParentSelectAll(this.getParentPath(node.$path));
              return true;
            }
            return false;
          }
        );
      }
    }
  }

  getParentPath(treePath: string) {
    let pathArray = treePath.split("/").filter((element) => element);
    if (pathArray.length > 1) {
      return treePath
        .split("/")
        .filter((element) => Utils.treeLastNode(treePath) !== element)
        .join("/");
    } else {
      return treePath;
    }
  }

  isAllSelected(treeNode: TreeAggregationNode): boolean {
    if (treeNode.hasChildren) {
      if (treeNode.items) {
        let count = 0;
        treeNode.items.forEach((element) => {
          if (element.$selected) {
            count++;
          }
        });
        return treeNode.items.length === count;
      } else {
        if (treeNode.$selected) {
          return true;
        } else {
          return false;
        }
      }
    } else if (!treeNode.hasChildren) {
      return Boolean(treeNode.$selected);
    } else {
      return true;
    }
  }

  override addFilter(
    item: AggregationItem | AggregationItem[],
    options: AddFilterOptions = {}
  ) {
    if (Array.isArray(item)) {
      item.forEach((element) => {
        let field = this.searchService.query.findFilter(
          (filter) =>
            (isValueFilter(filter) && filter.value === element.value) ||
            element.value === filter.display
        );
        if (!field && this.data) {
          options.replaceCurrent = this.replaceCurrent;
          if (!this.allowOr && this.allowAnd) {
            options.and = true; // The default mode is OR, unless explicitly forbidden
          }
          this.facetService.addFilterSearch(
            this.data,
            item,
            options,
            this.query,
            this.name
          );
          let filterValuesArray: any[] = []; // Initialize an empty array

          item.forEach((item) => {
            filterValuesArray.push(item.value); // Push each value into the array
          });
          // Now filterValuesArray contains all the filter values

          this.$gaService.event(
            googleAnalyticsConstants.action.search,
            googleAnalyticsConstants.category.search,
            this.searchService.query.text,
            0,
            true,
            {
              app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
              url: this.router.url,
              debug_mode: environment.googleAnalyticsDebugMode,
              ...this.commonService.createFilterClickObject(
                this.searchService.query.text,
                this.commonService.selectedScientificFocus,
                filterValuesArray
              ),
            }
          );
        }
      });
    }
  }
}
