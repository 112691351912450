<div class="modal-container help-and-support-modal">
  <div class="modal-header">
    <div class="help-support-container">
      <img id="logo" src="assets/img/question-mark.svg" />
      <p class="help-text font-family-rajdhani-semibold">HELP & SUPPORT</p>
    </div>

    <img
      tabindex="0"
      role="button"
      alt="close the help and support dialog"
      id="close-icon"
      class="close-popup"
      src="assets/img/close-icon.svg"
      (click)="modalRef.hide()"
    />
  </div>
  <div class="modal-body">
    <div
      tabindex="0"
      class="image-container"
      (click)="clickFaq('assets/img/faq.svg')"
      (keyup.enter)="clickFaq('assets/img/faq.svg')"
      *ngIf="commonService.isSDEApp()"
    >
      <img
        id="images"
        (mouseover)="hoverFaq('assets/img/faq.svg')"
        (mouseout)="unhoverFaq('assets/img/faq-active.svg')"
        [src]="
          activeImage === 'assets/img/faq.svg'
            ? 'assets/img/faq-active.svg'
            : 'assets/img/faq.svg'
        "
        alt="Click this card to access the frequently asked questions about the SDE"
      />
    </div>
    <div
      tabindex="0"
      class="image-container"
      (keyup.enter)="clickWalkthrough('assets/img/walkthrough.svg')"
      (click)="clickWalkthrough('assets/img/walkthrough.svg')"
    >
      <img
        id="images"
        (mouseover)="hoverWalkthrough('assets/img/walkthrough.svg')"
        (mouseout)="unhoverWalkthrough('assets/img/walkthrough-active.svg')"
        [src]="
          activeImage === 'assets/img/walkthrough.svg'
            ? 'assets/img/walkthrough-active.svg'
            : 'assets/img/walkthrough.svg'
        "
        alt="Click this card to enable a walkthrough that guides you on how to use the SDE"
      />
    </div>
    <div
      tabindex="0"
      class="image-container"
      (keyup.enter)="clickFeedback('assets/img/feedback.svg')"
      (click)="clickFeedback('assets/img/feedback.svg')"
    >
      <img
        class="feedbackImage"
        id="images"
        (mouseover)="hoverFeedback('assets/img/feedback.svg')"
        (mouseout)="unhoverFeedback('assets/img/feedback-active.svg')"
        [src]="
          activeImage === 'assets/img/feedback.svg'
            ? 'assets/img/feedback-active.svg'
            : 'assets/img/feedback.svg'
        "
        alt="Click this card to leave feedback about the SDE or ask a question."
      />
    </div>
    <div
      tabindex="0"
      class="image-container hide-on-mobile"
      (keyup.enter)="toggleHoverHelp('assets/img/hover-help.svg')"
      (click)="toggleHoverHelp('assets/img/hover-help.svg')"
      *ngIf="commonService.isSDEApp()"
    >
      <img
        id="images"
        (mouseover)="hoverHoverHelp('assets/img/hover-help.svg')"
        (mouseout)="unhoverHoverHelp('assets/img/hover-help-active.svg')"
        [src]="
          activeImage === 'assets/img/hover-help.svg'
            ? 'assets/img/hover-help-active.svg'
            : 'assets/img/hover-help.svg'
        "
        alt="Click this card to enable the hover help feature that guides you on how to use the SDE."
      />
    </div>
  </div>
  <div *ngIf="!ui.screenSizeIsLessOrEqual('xs')">
    <div class="border"></div>
    <ng-container *ngIf="commonService.isSDEApp(); else tdammNote">
      <p class="api-docu-link font-family-rajdhani-medium">
        Access the NASA SDE
        <a
          href="https://nasa-impact.github.io/sde-docs/index.html"
          target="_blank"
          rel="noopener noreferrer"
          class="link-click-item"
        >
          API Documentation here </a
        >.
      </p>
    </ng-container>
    <ng-template #tdammNote>
      <p class="tdamm-note font-family-rajdhani-semibold">
        Note:
        <span class="tdamm-note-content font-family-source-sans-pro-regular">
          This is the Beta version of the NASA Time Domain and Multi-Messenger
          Astronomy Search Interface(powered by the Science Discovery Engine).
          We are continually working on enhancing the content, features, and
          functionality of the interface.
        </span>
      </p>
    </ng-template>
  </div>

  <div *ngIf="ui.screenSizeIsLessOrEqual('xs')">
    <div style="padding-top: 23px">
      <ng-container *ngIf="commonService.isSDEApp(); else tdammNote">
        <p class="api-docu-link-mobile font-family-rajdhani-medium">
          Access the NASA SDE
          <a
            href="https://nasa-impact.github.io/sde-docs/index.html"
            target="_blank"
            rel="noopener noreferrer"
            class="link-click-item-mobile"
          >
            API Documentation here </a
          >.
        </p>
      </ng-container>
      <ng-template #tdammNote>
        <p class="tdamm-note-mobile font-family-rajdhani-semibold">
          Note:
          <span
            class="tdamm-note-content-mobile font-family-source-sans-pro-regular"
          >
            This is the Beta version of the NASA Time Domain and Multi-Messenger
            Astronomy Search Interface(powered by the Science Discovery Engine).
            We are continually working on enhancing the content, features, and
            functionality of the interface.
          </span>
        </p>
      </ng-template>
    </div>
  </div>
</div>
