<div *ngIf="query.filters" (sqClickOutside)="onClickOutside()">
  <div
    class="sq-simple-mode d-flex flex-row align-items-center"
    *ngIf="!advancedMode"
  >
    <app-sde-applied-filters-list
      [query]="query"
      [filter]="query.filters"
      [showField]="showField"
      [allowRemove]="allowRemove"
      [allowNesting]="allowNesting"
      (filterEdit)="onFiltersChange()"
    ></app-sde-applied-filters-list>

    <!-- <span *ngIf="!isSimpleFilter" class="text-nowrap">
        <a
          class="sq-filter-action"
          role="button"
          [sqTooltip]="'msg#filters.editTitle' | sqMessage"
          (click)="toggleEdit()"
        >
          <i class="fas fa-fw fa-edit"></i>
          <span> {{ "msg#filters.edit" | sqMessage }}</span>
        </a>
      </span> -->
  </div>

  <ng-container *ngIf="ui.screenSizeIsLessOrEqual('xs'); else closeTemplate">
    <div class="p-2 d-flex flex-row align-items-center justify-content-center">
      <button
        class="sde-filters-list font-family-inter-bold padding-for-close"
        (click)="clearFilters()"
      >
        CLEAR ALL FILTERS
      </button>
    </div>
  </ng-container>

  <ng-template #closeTemplate>
    <div
      *ngIf="ui.screenSizeIsGreater('sm')"
      class="p-2 d-flex flex-row align-items-center justify-content-center"
    >
      <button
        class="sde-filters-list font-family-inter-bold padding-for-close"
        (click)="closeFilterPopup()"
      >
        CLOSE
      </button>
    </div>
  </ng-template>

  <!-- <div
      class="sq-advanced-mode d-flex flex-row align-items-end"
      [ngClass]="advancedModeClass"
      *ngIf="advancedMode"
    >
      <sq-filters-editor
        [query]="query"
        [filter]="query.filters"
        [showField]="true"
        [allowRemove]="true"
        [allowNesting]="true"
        (filterEdit)="onFiltersChange()"
      ></sq-filters-editor>
  
      <a
        class="sq-filter-action ms-auto text-nowrap"
        role="button"
        [sqTooltip]="'msg#filters.saveTitle' | sqMessage"
        (click)="toggleEdit()"
      >
        <i class="fas fa-fw fa-check"></i>
        <span> {{ "msg#filters.save" | sqMessage }}</span>
      </a>
    </div> -->
</div>
