<div class="centered-main-div">
  <img class="no-data-image" id="logo" src="assets/img/bracets.svg" />
  <div *ngIf="!customText" class="no-result-text-one">
    <span>No results found</span>
  </div>
  <div class="no-result-text-two">
    <ng-container *ngIf="!customText">
      <span class="centered-span"
        >Try searching for different search terms or <br />edit your current
        search query</span
      >
    </ng-container>
    <ng-container *ngIf="customText">
      <span class="centered-span" [innerHTML]="customText"></span>
    </ng-container>
  </div>
  <div class="feedback-button-container">
    <button
      (click)="navigateToContactPage({ source: 'SDE' })"
      class="feedback-button"
    >
      Submit Feedback
    </button>
  </div>
</div>
