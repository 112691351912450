import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "no-data-found",
  templateUrl: "./no-data-found.component.html",
  styleUrls: ["./no-data-found.component.scss"],
})
export class NoDataFoundComponent implements OnInit {
  @Input() customText: string = "";

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToContactPage(source: any): void {
    this.router.navigate(["/contact-us"], { queryParams: source });
  }
}
