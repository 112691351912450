import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { BsNotification } from "@sinequa/components/notification/bootstrap/notification/notification";
import { Utils } from "@sinequa/core/base";
import {
  NotificationsService,
  NotificationState,
} from "@sinequa/core/notification";

export function notificationAnimations(
  timings: number | string
): AnimationTriggerMetadata[] {
  return [
    trigger("autoClose", [
      transition("1 => void", [animate(timings, style({ opacity: 0 }))]),
    ]),
  ];
}

@Component({
  selector: "sde-notification",
  templateUrl: "./sde-notification.component.html",
  animations: notificationAnimations(".15s ease-in-out"),
  styles: [
    `
      /* in dark mode, invert close button color */
      :host-context(.dark) button.btn-close {
        filter: invert(1);
      }
    `,
  ],
})
export class SdeNotificationComponent extends BsNotification implements OnInit {
  displayCloseIcon: boolean;

  constructor(protected override notificationsService: NotificationsService) {
    super(notificationsService);
  }

  override ngOnInit() {
    if (
      this.notification.autoClose &&
      this.notification.state === NotificationState.Initial
    ) {
      this.autoClose = true;
      if (
        this.notification.text &&
        this.notification.text.split(".")[1] === "urlCopiedToClipboard"
      ) {
        this.displayCloseIcon = true;
      } else {
        this.displayCloseIcon = false;
      }
      Utils.delay(5000).then((value) => {
        if (this.notification.state === NotificationState.Initial) {
          this.close();
        }
      });
    }
  }
}
