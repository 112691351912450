import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { LoginService } from "@sinequa/core/login";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HoverHelpDirective } from "../../directives/hover-help/hover-help.directive";
import { SdeFiltersModalComponent } from "../sde-filters-modal/sde-filters-modal.component";
import { CommonService } from "../services/common.service";
import { HeaderService } from "../services/header.service";
import { HoverHelpSharedService } from "../services/hover-help-shared.service";
import { ImagePreloaderService } from "../services/image-preloader.service";

@Component({
  selector: "app-filters-toolbar",
  templateUrl: "./filters-toolbar.component.html",
  styleUrls: ["./filters-toolbar.component.scss"],
  providers: [HoverHelpDirective],
})
export class FiltersToolbarComponent implements OnInit, OnChanges {
  constructor(
    protected ui: UIService,
    protected searchService: SearchService,
    protected commonService: CommonService,
    protected headerSrv: HeaderService,
    private modalService: BsModalService,
    private router: Router,
    private imgService: ImagePreloaderService,
    protected loginService: LoginService,
    private hoverHelpSharedService: HoverHelpSharedService
  ) {}

  @Input() showSciX: boolean;
  @Input() isMobile: boolean;
  @Input() showFilters: boolean;
  @Input() isSciFocusDropdownActive: boolean;
  @Input() enableNeuralSearch: boolean;
  @Input() filterCount: number;
  @Input() currentSciFocus: string;
  @Input() isDark: () => boolean;
  @Input() clearFilters: () => void;
  @Input() handleOutsideClick: () => void;
  @Input() getSciFocusList: () => void;
  @Input() closeDocument: () => void;
  @Input() dropdownToggled: (isDropdownActive: any) => void;
  @Output() neuralSearchToggled = new EventEmitter<any>();
  @Output() toggleFilters = new EventEmitter<any>();

  modalRef?: BsModalRef;
  sdeHeaderStyle: any = "";
  hoverHelpEnabled = false;

  sciFocusList: any[] = [
    {
      name: "All",
      icon: "assets/img/all-image.svg",
    },
    {
      name: "Astrophysics",
      icon: "assets/img/astrophysics-image.svg",
    },
    {
      name: "Biological & Physical Sciences",
      icon: "assets/img/biophysical-image.svg",
    },
    {
      name: "Earth Science",
      icon: "assets/img/earth-science.svg",
    },
    {
      name: "Heliophysics",
      icon: "assets/img/heliophysics-image.svg",
    },
    {
      name: "Planetary Science",
      icon: "assets/img/planetary-image.svg",
    },
  ];
  sciFocusListWhite: any[] = [
    {
      name: "All",
      icon: "assets/img/all-image-white.svg",
    },
    {
      name: "Astrophysics",
      icon: "assets/img/astrophysics-image-white.svg",
    },
    {
      name: "Biological & Physical Sciences",
      icon: "assets/img/biophysical-image-white.svg",
    },
    {
      name: "Earth Science",
      icon: "assets/img/earth-science-image-white.svg",
    },
    {
      name: "Heliophysics",
      icon: "assets/img/heliophysics-image-white.svg",
    },
    {
      name: "Planetary Science",
      icon: "assets/img/planetary-image-white.svg",
    },
  ];

  ngOnInit(): void {
    this.hoverHelpSharedService.hoverHelpEnabled$.subscribe((enabled) => {
      this.hoverHelpEnabled = enabled;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["showFilters"]) {
    }
  }

  openAppliedFilters() {
    this.modalRef = this.modalService.show(SdeFiltersModalComponent, {});
    this.commonService.modalRef = this.modalRef;
  }

  getIconForScientificFocus(currentSciFocus: string) {
    let item;
    if (this.isSciFocusDropdownActive) {
      item = this.sciFocusListWhite?.find(
        (element) => element.name === currentSciFocus
      );
    } else {
      item = this.sciFocusList?.find(
        (element) => element.name === currentSciFocus
      );
    }

    return item["icon"];
  }

  selectScientificFocus(sciFocusName: string, dropdown: BsDropdownDirective) {
    this.setBackgroundAsPerFocusSelection(sciFocusName);
    this.currentSciFocus = sciFocusName;
    this.searchService.selectScope(this.currentSciFocus);
    dropdown.hide();
  }

  isHome(): boolean {
    if (
      this.router.url.startsWith("/home") ||
      this.router.url.startsWith("/ej-home")
    )
      return true;
    else return false;
  }

  setBackgroundAsPerFocusSelection(focus: string = "All") {
    let img: HTMLImageElement | undefined;
    if (!this.isHome() && focus === "All") {
      img = this.imgService.getImage("assets/img/navbar-all.png");
    } else if (!this.isHome() && focus.includes("Earth Science")) {
      img = this.imgService.getImage("assets/img/navbar-earth-science.png");
    } else if (!this.isHome() && focus.includes("Planetary Science")) {
      img = this.imgService.getImage("assets/img/navbar-planetary.png");
    } else if (!this.isHome() && focus.includes("Astrophysics")) {
      img = this.imgService.getImage("assets/img/navbar-astrophysics.png");
    } else if (
      !this.isHome() &&
      focus.includes("Biological & Physical Sciences")
    ) {
      img = this.imgService.getImage("assets/img/navbar-bio-physical.png");
    } else if (!this.isHome() && focus.includes("Heliophysics")) {
      img = this.imgService.getImage("assets/img/navbar-heliophysics.png");
    }

    if (img && img?.src) {
      this.sdeHeaderStyle = {
        "background-image": `url(${img?.src})`,
        "background-size": "cover",
        height: "137px",
      };
    } else {
      this.sdeHeaderStyle = {
        "background-image": "inherit",
        "background-size": "cover",
      };
    }
  }

  onToggleNeuralSearch(event) {
    this.neuralSearchToggled.emit(event);
  }

  toggleFiltersEvent() {
    this.toggleFilters.emit();
  }

  getWalkthroughLabelForFilter(): string {
    if (this.commonService.isInTdammApp()) {
      return "4 - FILTERS";
    } else {
      return "6 - FILTERS";
    }
  }
}
