import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  Extract,
  PreviewExtractsPanelComponent,
} from "@sinequa/components/preview/preview-extracts-panel/preview-extracts-panel.component";
import { PreviewData } from "@sinequa/core/web-services";

@Component({
  selector: "app-sde-preview-extracts-panel",
  templateUrl: "./sde-preview-extracts-panel.component.html",
  styleUrls: ["./sde-preview-extracts-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SdePreviewExtractsPanelComponent extends PreviewExtractsPanelComponent {
  virtualScrollStyle: { height: string };
  mainContainerHeight: number;
  constructor(
    public override cdr: ChangeDetectorRef,
    public override sanitizer: DomSanitizer
  ) {
    super(cdr, sanitizer);
  }

  @ViewChild("virtualScrollBox") virtualScrollContainer: ElementRef;
  @ViewChild("extractsMain") extractsMainContainer: ElementRef;

  override ngOnChanges(changes: SimpleChanges) {
    if (
      changes.type &&
      changes.type.currentValue &&
      (changes.previewData || this.previewData)
    ) {
      this.fetchExtracts(this.previewData);
    }
    if (this.preview && !this.sub) {
      this.sub = this.preview.selectedId$.subscribe(() =>
        this.updateCurrentIndex()
      );
    }
  }

  setMainContainerHeight() {
    this.cdr.detectChanges();
    let top =
      this.extractsMainContainer.nativeElement.getBoundingClientRect().top;
    this.extractsMainContainer.nativeElement.style.height =
      window.innerHeight - top + "px";
    this.mainContainerHeight = window.innerHeight - top;
  }

  override fetchExtracts(data: PreviewData) {
    this.loading = true;
    this.preview.getHtml(this.type).subscribe((extracts) => {
      // extracts contains the html of extracts in chronological order
      // locations contains the list of start positions sorted by score
      const locations =
        data.highlightsPerCategory[this.type]?.values[0]?.locations || [];

      this.extracts = locations.map((l, relevanceIndex) => ({
        startIndex: l.start,
        relevanceIndex,
      })) as Extract[];

      this.extracts.sort((a, b) => a.startIndex - b.startIndex);

      this.extracts.forEach((ex, textIndex) => {
        ex.textIndex = textIndex;
        ex.text = this.sanitizer.bypassSecurityTrustHtml(extracts[textIndex]);
        ex.id = `${this.type}_${textIndex}`;
      });

      this.extracts.sort((a, b) => a.relevanceIndex - b.relevanceIndex);

      this.loading = false;
      this.buildSortAction();
      this.updateCurrentIndex();
      this.setMainContainerHeight();
      this.setVirtualScrollContainerHeight();
    });
  }

  setVirtualScrollContainerHeight() {
    this.cdr.detectChanges();
    if (this.virtualScrollContainer) {
      let top =
        this.virtualScrollContainer.nativeElement.getBoundingClientRect().top;
      //prettier-ignore
      this.virtualScrollContainer.nativeElement.style.height = (this.mainContainerHeight - top) + 'px';
    }
  }
}
