<div *ngIf="passages && documentsNb" class="text-end text-muted px-3 py-2">
  {{
    "msg#machine-learning.passagesFound"
      | sqMessage : { passagesNb: passages.length, documentsNb: documentsNb }
  }}
</div>

<ul class="list-group list-group-flush" *ngIf="passages">
  <li
    role="button"
    *ngFor="let passage of passages"
    (click)="openPreview(passage)"
    class="list-group-item list-group-item-action sde-passage"
    [ngClass]="{ selected: selectedPassage?.recordId == passage?.$record?.id }"
  >
    <div class="sde-passage-title d-flex align-content-center mb-1">
      <app-sde-result-title
        [record]="passage.$record"
        (titleClicked)="onTitleClicked($event, passage)"
        class="d-block text-truncate"
      >
      </app-sde-result-title>
    </div>

    <div class="sde-passage-text">
      <span
        *ngIf="passage.$record?.modified && !hideDate"
        class="extracts-date"
      >
        {{ passage.$record.modified | sqDate : dateFormat }} -
      </span>
      <span [innerHTML]="passage.highlightedText"></span>
    </div>
  </li>
</ul>
