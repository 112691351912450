import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { BsNotifications } from "@sinequa/components/notification/bootstrap/notifications/notifications";
import { NotificationsService } from "@sinequa/core/notification";

@Component({
  selector: "sde-notifications",
  templateUrl: "./sde-notifications.component.html",
})
export class SdeNotificationsComponent
  extends BsNotifications
  implements OnInit
{
  constructor(
    protected override changeDetectorRef: ChangeDetectorRef,
    protected override notificationsService: NotificationsService
  ) {
    super(notificationsService, changeDetectorRef);
  }
}
