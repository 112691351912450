import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
} from "@angular/core";
import { TopPassagesComponent } from "@sinequa/components/machine-learning/top-passages/top-passages.component";
import { SearchService } from "@sinequa/components/search";

import {
  AuditWebService,
  Record,
  TopPassage,
} from "@sinequa/core/web-services";

@Component({
  selector: "sde-top-passages",
  templateUrl: "sde-top-passages.component.html",
  styleUrls: ["./sde-top-passages.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdeTopPassagesComponent
  extends TopPassagesComponent
  implements OnChanges
{
  selectedPassage: any;
  @Input() selectedDoc: Record;

  constructor(
    protected override auditService: AuditWebService,
    protected override searchService: SearchService,
    public override cdRef: ChangeDetectorRef
  ) {
    super(auditService, searchService, cdRef);
  }

  override ngOnChanges(): void {
    this.documentsNb = 0;
    this.passages = undefined;
    if (this.results.topPassages?.passages?.length) {
      this.fetchPassagesRecords(this.results.topPassages.passages);
    }

    if (this.selectedDoc?.id == this.selectedPassage?.recordId) {
      this.selectedPassage = undefined;
    }
  }

  override fetchPassagesRecords(passages: TopPassage[]): void {
    this.passages = passages
      // Fill missing records
      .map((p) => ({
        ...p,
        $record:
          p.$record ||
          this.results.records.find((record) => record?.id === p.recordId),
      }))
      // Remove records that cannot be retrieved
      .filter((p) => p.$record)
      // Put answers first
      .sort(
        (a, b) =>
          (b.answerScore ? b.answerScore + 100 : b.score) -
          (a.answerScore ? a.answerScore + 100 : a.score)
      );

    this.notifyTopPassagesDisplay(this.passages);

    // Set the numbers of unique documents
    const uniqueRecords = [...new Set(this.passages.map((p) => p.recordId))];
    this.documentsNb = uniqueRecords.length;

    this.cdRef.detectChanges();
  }

  // Open the mini preview on text click
  override openPreview(passage: TopPassage) {
    this.selectedPassage = passage;
    this.notifyTopPassagesClick(passage);
    this.passageClicked.emit(passage);
  }
}
