<div class="card">
  <!-- Facet card header -->
  <div
    *ngIf="type !== 'metadata-viewer'"
    class="card-header cursor-default ps-2 pe-2 py-1"
    [ngClass]="{
      'sticky-header': type === 'main-filter',
      'messenger-bg': title === 'Messengers',
      'object-bg': title === 'Objects',
      'signal-bg': title === 'Signals'
    }"
  >
    <div class="d-flex align-items-center header-row">
      <!-- Facet card icon and title -->
      <div
        *ngIf="icon || title"
        class="d-flex align-items-center text-truncate me-auto"
        [title]="tooltip || title | sqMessage"
      >
        <ng-container *ngIf="type === 'entity-highlight'">
          <img
            *ngIf="(title | sqMessage) === 'Search terms'"
            class="mx-1"
            src="assets/img/entity_search_terms.svg"
            alt="search_terms"
          />
          <img
            *ngIf="(title | sqMessage) === 'Instruments'"
            class="mx-1"
            src="assets/img/entity_instruments.svg"
            alt="instruments"
          />
          <img
            *ngIf="(title | sqMessage) === 'Platforms'"
            class="mx-1"
            src="assets/img/entity_platforms.svg"
            alt="platforms"
          />
          <img
            *ngIf="(title | sqMessage) === 'Missions'"
            class="mx-1"
            src="assets/img/entity_missions.svg"
            alt="missions"
          />
        </ng-container>

        <ng-container *ngIf="type !== 'entity-highlight' && icon">
          <div class="card-icon {{ icon }} me-2" aria-hidden="true"></div>
        </ng-container>

        <div
          *ngIf="title"
          class="px-1 card-title font-family-rajdhani-semibold text-truncate mb-0 py-1 me-auto"
          [ngClass]="{
            'messenger-title': title === 'Messengers',
            'object-title': title === 'Objects',
            'signal-title': title === 'Signals'
          }"
        >
          {{ title | sqMessage }}
        </div>
      </div>

      <!-- Header custom template -->
      <ng-container [ngTemplateOutlet]="facetComponent?.headerTpl || headerTpl">
      </ng-container>

      <!-- DEFAULT FACET CARD ACTIONS (USED IN FACET FILTERING) -->
      <div
        *ngIf="type == 'default' || type == 'main-filter'"
        class="sq-primary-actions"
        [ngClass]="{actionsClass, 'messenger-bg': title === 'Messengers', 'object-bg': title === 'Objects', 'signal-bg': title === 'Signals'}"
        [sq-action-buttons]="{
          items: allActions,
          style: buttonsStyle,
          size: actionsSize,
          rightAligned: true
        }"
      ></div>
      <div
        *ngIf="type == 'default' || type == 'applied-filters'"
        class="sq-primary-actions"
        [ngClass]="actionsClass"
        [sq-action-buttons]="{
          items: allActions,
          style: buttonsStyle,
          size: actionsSize,
          rightAligned: true
        }"
      ></div>
      <div
        *ngIf="type == 'top-passages'"
        class="sq-primary-actions"
        [ngClass]="actionsClass"
        [sq-action-buttons]="{
          items: allActions,
          style: buttonsStyle,
          size: actionsSize,
          rightAligned: true
        }"
      ></div>

      <!-- CUSTOM ACTIONS FOR DOCUMENT PREVIEW -->
      <div *ngIf="type == 'preview'" class="preview-header-icons">
        <div class="icon-group">
          <ng-container *ngFor="let option of previewOptions">
            <img
              tabindex="0"
              role="button"
              [sqTooltip]="option.tooltip | sqMessage"
              [src]="option.imageSrc"
              class="preview-icon fa-light"
              (click)="option.function()"
              (keyup.enter)="option.function()"
              *ngIf="!option.hide"
              [alt]="option.alt"
            />
          </ng-container>
        </div>
      </div>

      <!-- CUSTOM ACTIONS FOR ENTITY HIGHLIGHTS IN FULL PAGE PREVIEW -->
      <div
        *ngIf="type === 'entity-highlight'"
        class="d-flex justify-content-between align-items-center"
      >
        <input
          class="mx-3 form-check-input check-margins facet-checkbox cursor-pointer"
          type="checkbox"
          [sqTooltip]="'msg#general.selectAllItems' | sqMessage"
          (change)="toggleSelect($event)"
          [checked]="isChecked"
          id="flexCheckDefault"
        />

        <img
          class="mx-3 cursor-pointer"
          [sqTooltip]="
            (sortState
              ? 'msg#preview.sortAlphabetically'
              : 'msg#preview.sortFrequency'
            ) | sqMessage
          "
          src="assets/img/sort-icon.png"
          (click)="toggleSort()"
          alt="sort-icon"
        />
        <i
          class="mx-3 fa-regular cursor-pointer"
          [ngClass]="{ ' fa-plus': _collapsed, ' fa-minus': !_collapsed }"
          (click)="toggleExpand($event)"
        ></i>
      </div>
    </div>

    <!-- Sub Header custom template -->
    <ng-container
      [ngTemplateOutlet]="facetComponent?.subHeaderTpl || subHeaderTpl"
    ></ng-container>

    <!-- View switching actions -->
    <div
      class="sq-view-actions"
      [ngClass]="viewActionsClass"
      [sq-action-buttons]="{
        items: viewActions,
        style: viewButtonsStyle || buttonsStyle,
        size: viewActionsSize || actionsSize,
        rightAligned: true
      }"
      *ngIf="!_collapsed && viewActionsAreSecondary && viewActions?.length > 1"
    ></div>

    <!-- Secondary actions -->
    <div
      class="sq-secondary-actions"
      [ngClass]="secondaryActionsClass"
      [sq-action-buttons]="{
        items: allSecondaryActions,
        style: secondaryButtonsStyle || buttonsStyle,
        size: secondaryActionsSize || actionsSize,
        rightAligned: true
      }"
      *ngIf="!_collapsed && allSecondaryActions.length"
    ></div>
  </div>

  <!-- Facet card content -->
  <sq-collapse
    [collapsed]="_collapsed"
    style="border-bottom: 1px solid #001526"
  >
    <ng-template>
      <ng-container
        *ngIf="!_settingsOpened && view"
        [ngTemplateOutlet]="view.template"
      ></ng-container>
      <ng-content *ngIf="!_settingsOpened && !view"></ng-content>
      <ng-container
        *ngIf="_settingsOpened"
        [ngTemplateOutlet]="facetComponent?.settingsTpl || settingsTpl"
      ></ng-container>
    </ng-template>
  </sq-collapse>

  <!-- Facet card footer -->
  <div
    class="card-footer"
    *ngIf="!_collapsed && (facetComponent?.footerTpl || footerTpl)"
  >
    <ng-container
      [ngTemplateOutlet]="facetComponent?.footerTpl || footerTpl"
    ></ng-container>
  </div>
</div>
