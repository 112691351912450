import { Inject, Injectable } from "@angular/core";
import { Record } from "@sinequa/core/web-services";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SqHttpClient } from "../../../../core/web-services/http-client";
import { HttpService } from "../../../../core/web-services/http.service";
import {
  StartConfig,
  START_CONFIG,
} from "../../../../core/web-services/start-config.web.service";
@Injectable({
  providedIn: "root",
})
export class RelatedDocumentsWebService extends HttpService {
  constructor(
    @Inject(START_CONFIG) startConfig: StartConfig,
    protected override httpClient: SqHttpClient
  ) {
    super();
  }

  public get(
    sourceDocumentId: string,
    queryName: string,
    exacthash: string
  ): Observable<any> {
    return this.httpClient
      .post<{ data: Record[]; documentDocs: Record[] }>(
        this.makeUrl("similardocuments"),
        {
          app: this.appName,
          sourceDocumentId,
          query: {
            name: queryName,
          },
          plugin: "GetRelatedDocuments",
          exacthash: exacthash,
        }
      )
      .pipe(map((response) => response));
  }
}
