import { PlatformLocation } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";

import { ResultTitle } from "@sinequa/components/result/result-title/result-title";

@Component({
  selector: "app-sde-result-title",
  templateUrl: "./sde-result-title.component.html",
  styleUrls: ["./sde-result-title.component.scss"],
})
export class SdeResultTitleComponent extends ResultTitle {
  @Input() appName: string;

  constructor(
    public override searchService: SearchService,
    private router: Router,
    private previewService: PreviewService,
    private platformLocation: PlatformLocation
  ) {
    super(searchService);
  }

  getDocumentNavigatorURL() {
    let query = this.searchService.query.copy();
    const urlTree = this.router.createUrlTree(["/preview"], {
      queryParams: {
        id: this.record.id,
        query: this.previewService.makeQuery(query).toJsonForQueryString(),
      },
    });

    const serializedUrl = this.router.serializeUrl(urlTree);
    const origin =
      this.platformLocation.protocol +
      "//" +
      this.platformLocation.hostname +
      (this.platformLocation.port ? ":" + this.platformLocation.port : "");

    // Used conditional logic for environment-specific URLs
    if (this.platformLocation.hostname === "localhost") {
      // Local development environment
      return `${origin}/#${serializedUrl}`;
    } else {
      // other environments
      return `${origin}/app/${this.appName}/#${serializedUrl}`;
    }
  }
}
