import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ResultSource } from "@sinequa/components/result/result-source/result-source";
import { SearchService } from "@sinequa/components/search";
import { ValueItem } from "@sinequa/core/app-utils";
import {
  NotificationsService,
  NotificationType,
} from "@sinequa/core/notification";

@Component({
  selector: "app-sde-result-source",
  templateUrl: "./sde-result-source.component.html",
  styleUrls: ["./sde-result-source.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdeResultSourceComponent extends ResultSource implements OnInit {
  constructor(
    public override searchService: SearchService,
    private notificationService: NotificationsService
  ) {
    super(searchService);
  }

  ngOnInit(): void {}

  override get source(): ValueItem[] {
    if (this.displayTreepath && this.record[this.treepathColumn]) {
      let treepath = this.record[this.treepathColumn][0] as string;
      if (treepath?.length >= 2) {
        if (treepath.charAt(0) !== "/") {
          treepath = "/".concat(treepath);
        }
        return treepath
          .substring(1, treepath.length - 1)
          .split("/")
          .slice(this.displayTreepathMinLevel, this.displayTreepathMaxLevel)
          .map((path, i, array) => ({
            display: path,
            value: "/" + array.slice(0, i + 1).join("/") + "/*",
          }));
      }
    }
    return [];
  }

  selectModified(item: ValueItem, event: Event) {
    let field = this.searchService.query.findFilter(
      (filter) => filter.display === item.display
    );
    if (!field) {
      if (this.searchService.addFieldSelect(this.treepathColumn, item)) {
        this.searchService.search();
      }
    } else {
      this.notificationService.notify(
        NotificationType.Info,
        "Filter already present",
        undefined,
        "",
        true
      );
    }
    event.stopImmediatePropagation();
    return false;
  }
}
