<div [ngClass]="{ 'hide-mobile-filter-scroll': hideFilterTab }">
  <app-sde-common-header
    [currentQuery]="searchService.query.name"
    [showCuratorMenu]="showCurator"
  ></app-sde-common-header>
  <!-- SHIMMER EFFECT -->
  <ng-container *ngIf="this.commonService._loadingFlag">
    <div class="d-flex">
      <div class="shimmer-container-filters">
        <div
          *ngFor="
            let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
          "
          class="shimmer-box-filters shimmer-effect"
        ></div>
        <br />
      </div>
      <div class="shimmer-container-results">
        <div
          *ngFor="
            let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
          "
          class="shimmer-box-results-section shimmer-effect"
        ></div>
        <br />
      </div>
    </div>
  </ng-container>

  <!-- Hide the filters, results when no results -->
  <ng-container *ngIf="results">
    <ng-container
      *ngIf="
        !this.commonService._loadingFlag || searchService.totalRowCount == 0
      "
    >
      <!-- CONTAINER FOR FILTERS TOGGLE AND SORTING WIDGET -->
      <!-- <div
        class="filters-toggle-container px-3"
        [ngClass]="{ 'filters-publications': showSciX && !isMobile }"
      > -->
      <!-- <div
          *ngIf="showSciX && !isMobile"
          class="filter-panel-scix font-family-inter-regular"
        >
          <p>
            Currently, there are no filtering options available for publications
            within SDE.
          </p>
        </div> -->

      <!-- MOBILE SCIENTIFIC FOCUS DROPDOWN -->
      <!-- <div
          [ngClass]="{
            show: hoverHelpEnabled
          }"
          [hoverHelpEnabled]="hoverHelpEnabled"
          appTooltip="Select the scientific focus area of your search. Only results included in that focus area will appear below.
      "
          joyrideStep="ninethStep"
          [stepContent]="customContentNineth"
          *ngIf="isMobile && !commonService.isInTdammApp()"
          class="sci-focus-group btn-group sde-text font-family-rajdhani-regular"
          dropdown
          #dropdown="bs-dropdown"
          [autoClose]="false"
          (isOpenChange)="dropdownToggled($event)"
        >
          <button
            id="button-basic"
            dropdownToggle
            (outsideClick)="handleOutsideClick()"
            type="button"
            class="sci-focus-btn-closed btn btn-primary"
            [ngClass]="
              isSciFocusDropdownActive
                ? 'sci-focus-btn-opened opened-width'
                : 'sci-focus-border-for-mobile'
            "
            aria-controls="dropdown-basic"
          >
            <img
              src="{{ getIconForScientificFocus(currentSciFocus) }}"
              [ngClass]="
                !isSciFocusDropdownActive
                  ? 'isClosed-spacing'
                  : 'isOpened-spacing'
              "
            />
            <div
              *ngIf="isSciFocusDropdownActive"
              class="selected-sci-focus"
              [ngClass]="{
                'infinite-animate-selected':
                  currentSciFocus === 'Biological & Physical Sciences' ||
                  currentSciFocus === 'Planetary Science'
              }"
            >
              <p>{{ currentSciFocus }}</p>
            </div>
            <i
              *ngIf="isSciFocusDropdownActive"
              [ngClass]="{ 'opened-icon-align': isSciFocusDropdownActive }"
              class="fas down-arrow-icon fa-chevron-down"
            ></i>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="sci-focus-dropdown dropdown-menu"
            [ngClass]="{ 'opened-width': isSciFocusDropdownActive }"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li role="menuitem" *ngFor="let sciFocus of getSciFocusList()">
              <div
                *ngIf="sciFocus.name !== currentSciFocus"
                (click)="selectScientificFocus(sciFocus.name, dropdown)"
                class="sci-focus-wrapper"
                [sqTooltip]="sciFocus.name"
              >
                <div class="sci-img-wrapper">
                  <img [src]="sciFocus.icon" alt="{{ sciFocus.name }}" />
                </div>
                <div
                  class="sci-name-wrapper overflow-hidden infinite-animate"
                  [ngClass]="{
                    'infinite-animate':
                      sciFocus.name === 'Biological & Physical Sciences' ||
                      sciFocus.name === 'Planetary Science'
                  }"
                >
                  <a class="sci-focus-item dropdown-item">
                    {{ sciFocus.name }}
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div> -->

      <!-- 9th STEP WALKTHROUGH TEMPLATE -->
      <ng-template #customContentNineth>
        <div class="joyride-step-header">
          <div
            class="joyride-step-title"
            [ngClass]="{
              'w-75': ui.screenSizeIsLessOrEqual('sm'),
              'w-100': ui.screenSizeIsGreater('sm')
            }"
          >
            3 - SEARCH FOCUS
          </div>
        </div>
        <p class="joyride-content">
          This dropdown menu includes options to filter results by Scientific
          Focus Area (Earth Science, Planetary Science, Astrophysics, Biological
          and Physical Sciences, Heliophysics, or All).
        </p>
      </ng-template>
      <!-- Search form -->

      <!-- MOBILE SEARCH FORM  -->
      <!-- <app-search-form
          appTooltip="The search bar displays the search query text. Click on the search bar to edit the query or begin a new search.
      "
          [ngClass]="{ show: hoverHelpEnabled }"
          [hoverHelpEnabled]="hoverHelpEnabled"
          joyrideStep="seventhStep"
          [prevTemplate]="prevButton"
          [nextTemplate]="nextButton"
          [stepContent]="customContentSeventh"
          class="flex-grow-1 flex-basis-0 sde-text font-family-rajdhani-regular"
          [ngClass]="{ 'mx-3': !commonService.isInTdammApp() }"
          *ngIf="isMobile"
        ></app-search-form> -->
      <ng-template #customContentSeventh>
        <div class="joyride-step-header">
          <div
            class="joyride-step-title"
            [ngClass]="{
              'w-75': ui.screenSizeIsLessOrEqual('sm'),
              'w-100': ui.screenSizeIsGreater('sm')
            }"
          >
            1 - SEARCH BAR
          </div>
        </div>

        <p class="joyride-content">
          The search bar displays the search query text. Click on the search bar
          to edit the query or begin a new search.
        </p>
      </ng-template>
      <ng-template #prevButton>
        <img
          class="cursor-pointer"
          id="close-icon"
          width="45"
          height="45"
          src="assets/img/walkthrough-previous.svg"
        />
      </ng-template>

      <!-- <ng-container *ngIf="!showSciX && !isMobile">
          <div class="left-group hide-on-mobile" id="filter-panel">
            <button
              [ngClass]="{ 'hide-click-events': hoverHelpEnabled }"
              [hoverHelpEnabled]="hoverHelpEnabled"
              appTooltip="Open the filter panel to refine search results by platforms, missions, instruments, and more.
              "
              class="btn btn-light filters-toggle font-family-rajdhani-bold sde-text"
              [attr.aria-expanded]="headerSrv.showFilters ? 'true' : 'false'"
              aria-label="Use the filter panel to refine search results by platform, missions, instruments and more."
              joyrideStep="eleventhStep"
              [stepContent]="customContentEleventh"
              (click)="toggleFilters()"
            >
              <i
                *ngIf="this.ui.screenSizeIsLessOrEqual('sm')"
                [ngClass]="
                  isDark()
                    ? 'fa fa-chevron-left px-2'
                    : 'fa fa-chevron-left px-2'
                "
                title="{{ 'msg#search.showFilters' | sqMessage }}"
              ></i>
              <i
                *ngIf="showFilters && !this.ui.screenSizeIsLessOrEqual('sm')"
                [ngClass]="
                  isDark()
                    ? 'fa fa-chevron-left px-2'
                    : 'fa fa-chevron-left px-2'
                "
                title="{{ 'msg#search.hideFilters' | sqMessage }}"
              ></i>
              <i
                *ngIf="!showFilters && !this.ui.screenSizeIsLessOrEqual('sm')"
                [ngClass]="
                  isDark()
                    ? 'fa fa-chevron-right px-2'
                    : 'fa fa-chevron-right px-2'
                "
                title="{{ 'msg#search.showFilters' | sqMessage }}"
              ></i>
              {{ "Filters" | uppercase }}
            </button>
            <ng-template #customContentEleventh>
              <div class="joyride-step-header">
                <div
                  class="joyride-step-title"
                  [ngClass]="{
                    'w-75': ui.screenSizeIsLessOrEqual('sm'),
                    'w-100': ui.screenSizeIsGreater('sm')
                  }"
                >
                  {{ getWalkthroughLabelForFilter() }}
                </div>
              </div>

              <p class="joyride-content">
                Click the Filters title to open the filters menu and refine your
                search results .
              </p>
            </ng-template>

            <ng-container *ngIf="searchService.query.filters">
              <div class="border-left"></div>
              <button
                type="button"
                class="sde-filters-list sde-filter-button sde-filter-button-border font-family-inter-semi-bold"
                (click)="openModal()"
              >
                View applied filters
              </button>
            </ng-container>

            <span
              *ngIf="filterCount !== 0"
              class="sde-filters-list-outer"
              role="button"
              [sqTooltip]="'msg#filters.clearTitle' | sqMessage"
              (click)="clearFilters()"
            >
              <span class="font-family-inter-bold">CLEAR ALL FILTERS</span>
            </span>
          </div>
        </ng-container> -->
      <!-- </div> -->

      <app-filters-toolbar
        [showSciX]="showSciX"
        [isMobile]="isMobile"
        [showFilters]="headerSrv._showFilters"
        [filterCount]="filterCount"
        [closeDocument]="closeDocument"
        [isDark]="isDark"
        [clearFilters]="clearFilters"
        [handleOutsideClick]="handleOutsideClick"
        [isSciFocusDropdownActive]="isSciFocusDropdownActive"
        [getSciFocusList]="getSciFocusList"
        [currentSciFocus]="currentSciFocus"
        [dropdownToggled]="dropdownToggled"
        [enableNeuralSearch]="enableNeuralSearch"
        (neuralSearchToggled)="toggleNeuralSearch($event)"
        (toggleFilters)="toggleFilters()"
      ></app-filters-toolbar>
    </ng-container>

    <!-- Tabs -->
    <div class="container-fluid main">
      <div
        joyrideStep="firstStepForTdammResults"
        [nextTemplate]="nextButton"
        [stepContent]="introTemplateResults"
      ></div>
      <div class="d-flex">
        <div
          #filtersBlock
          scroll-adjust
          id="leftFilter"
          class="left-filter"
          [ngClass]="{ 'filter-width-mobileView': isMobile }"
          *ngIf="this.headerSrv.showFilters && !isMobile"
        >
          <div id="search-results">
            <app-sde-search-facets
              *ngIf="hasFacets"
              [results]="results"
            ></app-sde-search-facets>
          </div>
        </div>
        <div
          scroll-adjust
          id="leftFilter"
          class="left-filter"
          [ngClass]="{ 'filter-width-mobileView': isMobile }"
          *ngIf="isMobile && hideFilterTab"
        >
          <div
            class="filter-label-mobile font-family-rajdhani-bold"
            *ngIf="isMobile"
          >
            <i class="fa-solid fa-filter"></i>
            <span>{{ "Filters" | uppercase }}</span>

            <i
              (click)="toggleFiltersForMobile()"
              class="fa-regular fa-xmark close-filter-icon"
            ></i>
          </div>
          <div
            id="search-results"
            *ngIf="searchService.query.filters && isMobile"
          >
            <app-sde-facet-card
              [title]="'Applied Filters'"
              [type]="'applied-filters'"
              [collapsible]="true"
              [startCollapsed]="true"
              ><div class="applied-filter-open">
                <app-sde-applied-filters
                  *ngIf="searchService.query.filters"
                  [query]="searchService.query"
                  [closeOnClickOutside]="true"
                  [advancedModeClass]="
                    'position-absolute card border shadow p-3'
                  "
                  (filterEdit)="searchService.search()"
                >
                </app-sde-applied-filters>
              </div>
            </app-sde-facet-card>
          </div>

          <div id="search-results">
            <app-search-facets
              *ngIf="hasFacets"
              [results]="results"
            ></app-search-facets>
          </div>
        </div>

        <div
          #resultsEl
          [ngClass]="{
            'result-cards-container-noscroll':
              (previewOpened && !isMobile) || (showSciXMetadata && !isMobile),
            'result-cards-container': ui.screenSizeIsGreater('sm'),
            'result-cards-container-mobile': ui.screenSizeIsLessOrEqual('xs')
          }"
        >
          <div
            class="tabs-container navbar-sde-tabs"
            #tabsEl
            joyrideStep="twelveStep"
            [stepContent]="customContentTwelveth"
          >
            <app-sde-tabs
              [searchText]="searchService.query.text"
              [results]="results"
              [sciXCount]="sciXCount"
              (events)="handleTabChange($event)"
            ></app-sde-tabs>
          </div>

          <ng-template #customContentTwelveth>
            <div class="joyride-step-header">
              <div
                class="joyride-step-title"
                style="color: rgb(2, 61, 139)"
                [ngClass]="{
                  'w-75': ui.screenSizeIsLessOrEqual('sm'),
                  'w-100': ui.screenSizeIsGreater('sm')
                }"
              >
                {{ getWalkthroughLabel() }}
              </div>
            </div>
            <p class="joyride-content">
              Switch among these tabs to view particular types of search
              results.
            </p>
          </ng-template>

          <ng-container *ngIf="commonService.resultsCardLoader">
            <div
              *ngFor="
                let item of [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
                ]
              "
              class="shimmer-box-cards shimmer-effect"
            ></div>
            <br />
          </ng-container>

          <app-sde-common-results
            *ngIf="!showSciX"
            (shortPreviewOpened)="toggleFiltersPanel($event)"
            (manualTabNavigation)="tabSwitchedManually($event)"
            [isDidYouMean]="isDidYouMean"
            [didYouMeanObj]="didYouMeanObj"
            [isShortPreviewOpen]="!headerSrv._showFilters"
            [currentAppLabel]="commonService.appLabel"
            [currentQuery]="searchService.query.name"
            [results]="results"
            [showSciX]="showSciX"
            [sciXCount]="sciXCount"
            [neuralData]="neuralData"
          ></app-sde-common-results>

          <div class="container-fluid main">
            <div class="d-flex">
              <div class="d-flex" [ngClass]="{ 'width-35': showSciXMetadata }">
                <div
                  id="results-page"
                  class="result-cards-container"
                  [ngClass]="{
                    'result-cards-container':
                      !this.openedDoc && !this.showSciXMetadata,
                    'result-cards-container-noscroll':
                      this.openedDoc || this.showSciXMetadata,
                    'scix-mobile-view': this.showSciXMetadata && this.isMobile
                  }"
                >
                  <ng-container
                    *ngIf="
                      showSciX &&
                      !commonService.resultsCardLoader &&
                      searchText.trim() !== ''
                    "
                  >
                    <div
                      class="scix-info-container my-3 py-3 px-4"
                      *ngIf="sciXData && sciXCount !== 0"
                    >
                      <div
                        class="font-family-rajdhani-bold scix-powered-text pb-3"
                      >
                        Publications on Science Discovery Engine are powered by
                        the NASA Science Explorer (SciX)
                      </div>

                      <div class="scix-link-container">
                        <img
                          src="assets/img/scix-link.svg"
                          alt="Image Alt Text"
                          width="20"
                          height="20"
                        />
                        <a
                          class="font-family-rajdhani-medium scix-link"
                          href="https://scixplorer.org/"
                          target="_blank"
                          >Explore SciX</a
                        >
                      </div>
                    </div>
                    <div
                      class="record d-flex"
                      *ngFor="let doc of sciXData; let i = index"
                      (click)="openScixMetadataViewer(doc, i)"
                      [class.selected]="selectedScixIndex === i"
                      [stepPosition]="'top'"
                      [joyrideStep]="i == 0 ? 'thirteenStep' : 'null'"
                      [stepContent]="customContentThirteen"
                      [hoverHelpEnabled]="hoverHelpEnabled"
                      [appTooltip]="
                        i === 0
                          ? 'Search results are displayed as cards with basic metadata. Click on a card title to open a search result in preview mode and view more information.'
                          : ''
                      "
                    >
                      <div class="flex-grow-1 w-75 ml-0 cursor-pointer">
                        <!--  GitHub Issue #875 - Max-Width Removed  -->
                        <div
                          class="d-flex align-items-center result-title-main-div"
                        >
                          <p
                            class="scix-title font-family-rajdhani-bold"
                            [appMathJax]="doc.title"
                          ></p>
                        </div>

                        <div
                          class="sq-relevant-extracts sq-text ng-star-inserted font-family-public-sans-regular"
                        >
                          <p
                            class="scix-desc"
                            [appMathJax]="doc.abstract"
                            *ngIf="doc.abstract"
                          ></p>
                          <p class="scix-desc" *ngIf="!doc.abstract">
                            No Abstract
                          </p>
                        </div>
                        <span
                          class="extracts-date-scix font-family-inter-regular"
                          >Published On {{ doc.date | sqDate : dateFormat }}
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !this.commonService._loadingFlag &&
                      !commonService.resultsCardLoader &&
                      (sciXCount == 0 || !searchText) &&
                      tabName == 'Publications'
                    "
                  >
                    <ng-container *ngIf="searchText">
                      <no-data-found></no-data-found>
                    </ng-container>
                    <ng-container *ngIf="!searchText">
                      <no-data-found
                        [customText]="
                          'Searching without a term is not valid for publications. <br /> Please try again with a search term'
                        "
                      ></no-data-found>
                    </ng-container>
                  </ng-container>
                  <div
                    class="row d-flex"
                    *ngIf="
                      showSciX && sciXCount !== 0 && searchText.trim() !== ''
                    "
                  >
                    <div class="col-xs-12 col-12">
                      <pagination
                        previousText="&lsaquo;"
                        nextText="&rsaquo;"
                        firstText="&laquo;"
                        lastText="&raquo;"
                        [boundaryLinks]="showBoundaryLinks"
                        [itemsPerPage]="itemsPerPage"
                        [totalItems]="sciXCount"
                        [maxSize]="5"
                        (pageChanged)="pageChangedForScix($event)"
                      ></pagination>
                    </div>
                  </div>
                </div>
              </div>
              <div class="width-65" *ngIf="showSciXMetadata">
                <app-scix-metadata-viewer
                  class="scix-container"
                  [scixMetaData]="selectedSciXMetadata"
                  (close)="closeScixMetadataViewer()"
                ></app-scix-metadata-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CONTAINER FOR FACETS AND RESULT CARS, TABS  -->
    <!-- </ng-container> -->

    <!-- Filter icon for mobile -->

    <div
      *ngIf="isMobile && !hideFilterTab && !showSciX"
      (click)="toggleFiltersForMobile()"
      class="filter-for-mobile font-family-rajdhani-bold"
      joyrideStep="mobileFilterStep"
      [stepContent]="mobileFilterContent"
    >
      <i class="fa-regular fa-filter mobile-filter-icon"></i>
      <span>FILTERS</span>
    </div>
    <div
      *ngIf="showSciX && isMobile && !showSciXMetadata"
      class="filter-for-mobile-scix font-family-inter-regular"
    >
      <p class="scix-filters-text">
        Currently, there are no filtering options available for publications
        within SDE.
      </p>
    </div>

    <ng-template #mobileFilterContent>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          {{ getWalkthroughLabelForFilterMobile() }}
        </div>
      </div>

      <p class="joyride-content">
        Click the Filters title to open the filters menu and refine your search
        results .
      </p>
    </ng-template>
    <div class="hover-help-btn" *ngIf="hoverHelpEnabled">
      <button
        class="font-family-rajdhani-medium"
        (click)="stopHoverHelpFeature()"
      >
        <img
          id="close-icon"
          class="close-popup"
          src="assets/img/cross-hover-help.svg"
        />
        CLICK HERE TO EXIT HOVER HELP
      </button>
    </div>

    <ng-template #tdammtagStepContent>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          {{
            ui.screenSizeIsLessOrEqual("xs")
              ? "6 - TDAMM TAGS"
              : "7 - TDAMM TAGS"
          }}
        </div>
      </div>

      <p class="joyride-content">
        Tags above each search result identify messenger, object and signal
        types and subtypes that correspond to the result.
      </p>
      <p class="joyride-content">
        Clicking on any TDAMM tag opens a window with more detailed information
        about the tag categories and subcategories.
      </p>
    </ng-template>

    <ng-template #customContentThirteen>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          {{ getWalkthroughLabelForResultCard() }}
        </div>
      </div>

      <p class="joyride-content">
        Search results are displayed as cards with basic metadata. Click on a
        card title to open a search result in preview mode.
      </p>
    </ng-template>

    <ng-template #introTemplateResults>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title walkThrough-title"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          TDAMM RESULTS PAGE WALKTHROUGH
        </div>
      </div>
      <p class="joyride-content">
        Welcome to the NASA Time Domain and Multi Messenger Astronomy Search
        Interface (powered by the Science Discovery Engine).
      </p>
      <p class="joyride-content">
        This walkthrough will guide you through the features of the interface
        step by step.
      </p>
      <p class="joyride-content">
        A walkthrough is available for each page of the Search interface. You
        can access the walkthroughs by clicking the Help button in the top right
        area of the pages.
      </p>
      <p class="joyride-content">
        You can go forward using the ‘Next’ button or go back to the step before
        using the ‘Previous’ button.
      </p>
      <p class="joyride-content">
        You can skip the tutorial by clicking the X on the top right corner.
      </p>
    </ng-template>

    <ng-template #nextButton>
      <img
        class="cursor-pointer"
        id="close-icon"
        width="45"
        height="45"
        src="assets/img/walkthrough-next.svg"
      />
    </ng-template>
  </ng-container>
</div>
