import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from "@angular/core";
import { PreviewEntityFacetComponent } from "@sinequa/components/preview/preview-entity-facet/preview-entity-facet.component";
import { AppService } from "@sinequa/core/app-utils";
import { map, tap } from "rxjs";
@Component({
  selector: "app-sde-preview-entity-facet",
  templateUrl: "./sde-preview-entity-facet.component.html",
  styleUrls: ["./sde-preview-entity-facet.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdePreviewEntityFacetComponent extends PreviewEntityFacetComponent {
  constructor(
    public override appService: AppService,
    public override cdRef: ChangeDetectorRef
  ) {
    super(appService, cdRef);
  }

  @Input() allSelected: boolean = false;

  ngOnInit() {
    if (this.entity === "matchlocations") {
      this.preview.toggleHighlight(this.entity);
    }
    this.highlighted$.subscribe((data) => {});
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.column = this.appService.getColumn(this.entity);
      this.updateValues();
    }
    if (changes.sortFreq) {
      this.updateSort();
    }
    if (this.preview && !this.sub) {
      // Listen to changes of the preview's currently selected id, and update the state of this component accordingly
      this.sub = this.preview.selectedId$
        .pipe(tap((id) => this.updateSelected(id)))
        .subscribe(() => this.cdRef.detectChanges());

      // Listen to changes of the preview's currently highlighted categories, and update the state of this component accordingly
      this.highlighted$ = this.preview.highlights$.pipe(
        map((hl) => (hl.includes(this.entity) ? "highlighted" : ""))
      );
    }

    if (changes.allSelected) {
      this.preview.toggleHighlight(this.entity);
    }
  }
}
