import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HoverHelpSharedService {
  // hover help observable
  private hoverHelpEnabledSubject = new BehaviorSubject<boolean>(false);
  hoverHelpEnabled$ = this.hoverHelpEnabledSubject.asObservable();

  constructor() {}

  toggleHoverHelp(enabled: boolean): void {
    this.hoverHelpEnabledSubject.next(enabled);
  }
}
