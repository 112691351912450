import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { IntlService } from "@sinequa/core/intl";
import { PreviewRelatedDocsPanelComponent } from "../../app/sde-common-full-preview/preview-related-docs-panel/preview-related-docs-panel.component";
import { RelatedDocumentsWebService } from "../../app/services/related-documents.web.service";

@Component({
  selector: "app-sde-preview-related-docs-panel",
  templateUrl: "./sde-preview-related-docs-panel.component.html",
  styleUrls: ["./sde-preview-related-docs-panel.component.scss"],
})
export class SdePreviewRelatedDocsPanelComponent
  extends PreviewRelatedDocsPanelComponent
  implements OnInit
{
  constructor(
    public override searchService: SearchService,
    public override previewService: PreviewService,
    public override cdr: ChangeDetectorRef,
    public override relatedDocumentsWebService: RelatedDocumentsWebService,
    public override intlService: IntlService
  ) {
    super(
      searchService,
      previewService,
      cdr,
      relatedDocumentsWebService,
      intlService
    );
  }

  ngOnInit(): void {}
}
