import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { PreviewFrameService } from "@sinequa/components/preview";
import { Preview } from "@sinequa/components/preview/preview.component";
import { PreviewService } from "@sinequa/components/preview/preview.service";
import { SearchService } from "@sinequa/components/search";
import { UserPreferences } from "@sinequa/components/user-settings";
import { UIService } from "@sinequa/components/utils";
import { AppService } from "@sinequa/core/app-utils";
import { SdePreviewData } from "./../../model/extended.interface";

@Component({
  selector: "app-sde-preview",
  templateUrl: "./sde-preview.component.html",
  styleUrls: ["./sde-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdePreviewComponent
  extends Preview
  implements OnInit, AfterViewInit
{
  constructor(
    public override previewService: PreviewService,
    public override previewFrames: PreviewFrameService,
    public override sanitizer: DomSanitizer,
    public override appService: AppService,
    public override searchService: SearchService,
    public override prefs: UserPreferences,
    public override cdRef: ChangeDetectorRef,
    public override ui: UIService,
    public override el: ElementRef<HTMLElement>
  ) {
    super(
      previewService,
      previewFrames,
      sanitizer,
      appService,
      searchService,
      prefs,
      cdRef,
      ui,
      el
    );
  }

  private _entityHighlighted: boolean;
  private _extractsHighlighted: boolean;
  highlightEntitiesPref: any;
  highlightExtractsPref: any;
  override data?: SdePreviewData;

  @Input() previewType: string = "SHORT";

  @Input() set entityHighlighted(value: boolean) {
    this._entityHighlighted = value;
    this.highlightEntitiesPref = this._entityHighlighted;
    this.updateHighlights();
  }

  @Input() set extractsHighlighted(value: boolean) {
    this._extractsHighlighted = value;
    this.highlightExtractsPref = this._extractsHighlighted;
    this.updateHighlights();
  }

  @ViewChild("previewContainer") previewContainer: ElementRef;

  previewData: SdePreviewData | undefined;

  ngOnInit(): void {}

  ngAfterViewInit() {
    // console.log("ngAfterViewInit Called");
    this.cdRef.detectChanges();
    let top = this.previewContainer.nativeElement.getBoundingClientRect().top;
    this.previewContainer.nativeElement.style.height =
      window.innerHeight - top + "px";
  }

  override ngOnChanges(changes: SimpleChanges) {
    // Update the preview content
    if (changes.id || changes.query || changes.customHighlights) {
      this.loading = true;
      const query = this.query || this.searchService.query;
      this.previewService
        .getPreviewData(this.id, query, this.customHighlights)
        .subscribe((data) => {
          this.data = data as SdePreviewData;
          this.loading = false;
          if (this.url) {
            this.previewFrames.unsubscribe(this.url);
          }
          this.pdfDownloadAction.href = data.record.pdfUrl;
          this.url = this.appService.updateUrlForCors(
            data.documentCachedContentUrl
          );

          if (this.safeUrl) {
            this.safeUrl = undefined;
            this.cdRef.detectChanges(); // Destruct and reconstruct the iframe to prevent navigation issues
          }

          this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.url
          );

          // Subscribe to the iframe load event
          this.previewFrames.subscribe(this.url, "ready", () => {
            this.onReady();
            this.disableLinksInDocumentPreview(this.iframe); // Disable links after the iframe is ready
          });

          this.cdRef.detectChanges();
        });
    } else if (changes.preferenceName || changes.highlights) {
      this.updateHighlights();
    }

    // Check For Entity Highlight / Extracts Highlight and toggle the same
    if (changes.highlightEntities) {
      this.toggleEntities(changes.highlightEntities.currentValue);
    }

    if (changes.highlightExtracts) {
      this.toggleExtracts(changes.highlightExtracts.currentValue);
    }

    this.updateActions();
  }

  stopLoader(event: any) {
    this.loading = false;
  }

  override onLoad() {
    if (this.iframe && this.loading) {
      this.loading = false;
      this.updateActions();
      this.ready.emit();
    }
  }

  disableLinksInDocumentPreview(iframe: ElementRef) {
    const iframeDoc =
      iframe.nativeElement.contentDocument ||
      iframe.nativeElement.contentWindow.document;

    if (iframeDoc) {
      // Apply styles to disable links immediately
      this.applyStylesforIframe(iframeDoc);

      // Check if document is already loaded
      if (iframeDoc.readyState === "complete") {
      } else {
        // Add an event listener for when the document is fully loaded
        iframeDoc.addEventListener("DOMContentLoaded", () => {
          this.applyStylesforIframe(iframeDoc);
        });
      }
    }
  }

  applyStylesforIframe(doc: Document) {
    const style = doc.createElement("style");
    style.innerHTML = "* { pointer-events: none; }";
    doc.head.appendChild(style);
  }
}
