import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Action } from "@sinequa/components/action";
import { FacetService } from "@sinequa/components/facet";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { AppService } from "@sinequa/core/app-utils";
import { Utils } from "@sinequa/core/base";
import { LoginService } from "@sinequa/core/login";
import { PrincipalWebService, Record } from "@sinequa/core/web-services";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { Subscription } from "rxjs";
import { FEATURES, URLS } from "../../config";
import { HoverHelpDirective } from "../../directives/hover-help/hover-help.directive";
import { googleAnalyticsConstants } from "../analytics/google-analytics.constant";
import { appConfig } from "../app-config/app-config";
import { SdeCommonHelpSupportModalComponent } from "../sde-common-help-support-modal/sde-common-help-support-modal.component";
import { AcronymService } from "../services/acronym.service";
import { CommonService } from "../services/common.service";
import { HeaderService } from "../services/header.service";
import { HoverHelpSharedService } from "../services/hover-help-shared.service";
import { ImagePreloaderService } from "../services/image-preloader.service";

@Component({
  selector: "app-sde-common-header",
  templateUrl: "./sde-common-header.component.html",
  styleUrls: ["./sde-common-header.component.scss"],
  providers: [HoverHelpDirective],
})
export class SdeCommonHeaderComponent implements OnChanges {
  config: any;
  acronymData: any;
  autofocus = 0;
  text: any;
  modalRef: BsModalRef;
  hoverHelpEnabled = false;
  navGovUrl = appConfig.nasaGovURL;

  /**
   * Suggest query with which to perform autocompletion
   */
  @Input() suggestQuery: string;

  /**
   * Minimum delay (in ms) between suggest queries
   */
  @Input() suggestDelay: number = 200;

  /**
   * Whether or not to enable autocompletion
   */
  @Input() autocompleteEnabled: boolean = false;

  /**
   * Used to hide icons
   * */

  @Input() readMoreMode: string = "initial";

  @Input() currentQuery: string = "";

  @Input() source: string = "SDE";

  form: FormGroup;
  searchControl: AbstractControl | null;
  acronymSearchText: string = "";

  inputErrorMessage: string;
  isSciFocusDropdownActive: boolean = false;
  currentSciFocus: string = "";
  sdeHeaderStyle: any = "";
  sciFocusList: any[] = [
    {
      name: "All",
      icon: "assets/img/all-image-white.svg",
    },
    {
      name: "Astrophysics",
      icon: "assets/img/astrophysics-image-white.svg",
    },
    {
      name: "Biological & Physical Sciences",
      icon: "assets/img/biophysical-image-white.svg",
    },
    {
      name: "Earth Science",
      icon: "assets/img/earth-science-image-white.svg",
    },
    {
      name: "Heliophysics",
      icon: "assets/img/heliophysics-image-white.svg",
    },
    {
      name: "Planetary Science",
      icon: "assets/img/planetary-image-white.svg",
    },
  ];

  @Input() showCuratorMenu: boolean = false;

  // Whether the menu is shown on small screens
  public _showMenu = false;

  // User Welcome text in the login page header
  // @Input() welcomeUserText: string = "Waiting for Authentication...";

  // Document "opened" via a click (opens the preview facet)
  public openedDoc?: Record;
  menu: Action | undefined;

  // Alerts actions
  curatorReportAction: Action;
  duplicateReportAction: Action;
  piiReportAction: Action;
  sensitiveReportAction: Action;
  performanceReportAction: Action;

  @Input() icon: string = "fas fa-clipboard-list";
  @Input() autoAdjust: boolean = true;
  @Input() autoAdjustBreakpoint: string = "xl";
  @Input() collapseBreakpoint: string = "sm";
  @Input() size: string;

  @ViewChild("dropdown") dropdown: BsDropdownDirective;

  constructor(
    public ui: UIService,
    public searchService: SearchService,
    public facetService: FacetService,
    public appService: AppService,
    public headerSrv: HeaderService,
    private cdr: ChangeDetectorRef,
    public loginService: LoginService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private configService: AcronymService,
    public formBuilder: FormBuilder,
    private principalService: PrincipalWebService,
    public commonService: CommonService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private imgService: ImagePreloaderService,
    private deviceService: DeviceDetectorService,
    private $gaService: GoogleAnalyticsService,
    private hoverHelpSharedService: HoverHelpSharedService
  ) {
    this.showCuratorMenu = this.headerSrv.showCuratorsMenu;
    this.CuratorMenu();
    this.headerSrv.getPeopleFinderData();
    this.route.queryParamMap.subscribe((p: any) => {
      if (p?.params?.source) this.source = p.params.source;
    });
  }

  CuratorMenu() {
    this.curatorReportAction = new Action({
      text: "msg#curator.curatorReportHomepage",
      title: "msg#curator.curatorReportHomepage",
      action: () => {
        window.open(URLS.RELATIVE_URLS.curatorReportHomepage, "_blank");
      },
    });

    this.duplicateReportAction = new Action({
      text: "msg#curator.duplicateReportItem",
      title: "msg#curator.duplicateReportItem",
      action: () => {
        window.open(URLS.RELATIVE_URLS.duplicateReportItem, "_blank");
      },
    });

    this.piiReportAction = new Action({
      text: "msg#curator.piiReportItem",
      title: "msg#curator.piiReportItem",
      action: () => {
        window.open(URLS.RELATIVE_URLS.piiReportItem, "_blank");
      },
    });

    this.sensitiveReportAction = new Action({
      text: "msg#curator.sensitiveReportItem",
      title: "msg#curator.sensitiveReportItem",
      action: () => {
        window.open(URLS.RELATIVE_URLS.sensitiveReportItem, "_blank");
      },
    });

    this.performanceReportAction = new Action({
      text: "msg#curator.performanceReportItem",
      title: "msg#curator.performanceReportItem",
      action: () => {
        window.open(URLS.RELATIVE_URLS.performanceReportItem, "_blank");
      },
    });
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  navigateToSdeApp() {
    window.location.replace(
      window.location.origin + "/app/" + appConfig.sdeApp + "/#/home"
    );

    //google analytics service call - To be updated
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.environmentalJustice,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.commonService.getAppDetailsForGA().homePage,
        url: window.location.href,
        route: this.router.url,
      }
    );
  }

  navigateToRoutes() {
    if (this.commonService.isInTdammApp()) {
      this.router.navigate(["/tdamm"]);
    } else if (this.source === "SDE") {
      this.router.navigate(["/home"]);
      this.resetScientificFocus("All");
    }
  }

  updateMenu() {
    const userActions: Action[] = [];

    if (this.principalService.principal) {
      userActions.push(this.curatorReportAction);
      userActions.push(new Action({ separator: true }));
      userActions.push(this.duplicateReportAction);
      userActions.push(this.piiReportAction);
      userActions.push(this.sensitiveReportAction);
      userActions.push(this.performanceReportAction);
    }

    this.menu = new Action({
      icon: this.icon,
      text: "msg#curator.reportsMenu",
      children: userActions,
    });
  }

  /**
   * Whether the UI is in dark or light mode
   */
  isDark(): boolean {
    return document.body.classList.contains("dark");
  }

  set welcomeTitle(newValue: string) {
    this.welcomeTitle = newValue;
  }
  /**
   * On small screens only show the search form when the facets are displayed
   */
  get showForm(): boolean {
    return this.ui.screenSizeIsGreaterOrEqual("sm");
  }

  /**
   * Controls visibility of filters (small screen sizes)
   */
  get showFilters(): boolean {
    const flag =
      (!this.headerSrv.hasFilterEnabled &&
        this.ui.screenSizeIsGreaterOrEqual("lg")) ||
      this.headerSrv._showFilters;
    return flag;
  }

  /**
   * Controls visibility of menu (small screen sizes)
   */
  get showMenu(): boolean {
    return (
      this.ui.screenSizeIsGreaterOrEqual("sm") ||
      (this.headerSrv.showMenu && !this.headerSrv.showFilters)
    );
  }

  /**
   * Controls the state of the header
   */
  get isMinimal(): boolean {
    return this.headerSrv.isMinimal;
  }

  /**
   * Returns the list of features activated in the top right menus.
   * The configuration from the config.ts file can be overriden by configuration from
   * the app configuration on the server
   */
  public get features(): string[] {
    // If header is minimal, there shouldn't be any query features displayed
    /*     if (this.isMinimal) {
            return [];
          } */

    if (
      this.appService.app &&
      this.appService.app.data &&
      this.appService.app.data.features
    ) {
      return <string[]>(<any>this.appService.app.data.features);
    }

    return FEATURES;
  }

  get isFilterToggleVisible() {
    var eleId = document.getElementById("btnFilterToggle");
    if (eleId != null) {
      var style = window.getComputedStyle(eleId);
      if (style.display === "none") this.headerSrv._showFilters = false;
      else this.headerSrv._showFilters = true;
    } else this.headerSrv._showFilters = false;
    return this.headerSrv._showFilters;
  }

  /**
   * On initialization set the Welcome User Text
   */
  ngOnInit() {
    if (this.commonService.selectedScientificFocus !== "") {
      let focus = this.commonService.selectedScientificFocus;
      this.setBackgroundAsPerFocusSelection(focus);
      this.setScientificFocus(focus);
    } else {
      this.setBackgroundAsPerFocusSelection("All");
      this.setScientificFocus("All");
    }

    this.updateMenu();
    this._loginSubscription = this.loginService.events.subscribe((event) => {
      if (event.type === "session-changed") {
        this.updateMenu();
      }
      this.getScopeDetailsFromURL();
    });
    this._principalSubscription = this.principalService.events.subscribe(
      (event) => {
        this.updateMenu();
      }
    );
    this.hoverHelpSharedService.hoverHelpEnabled$.subscribe((enabled) => {
      this.hoverHelpEnabled = enabled;
      if (enabled) {
        // Disable clickable items on the page
        document.querySelectorAll(".clickable-item").forEach((item) => {
          item.classList.add("disabled");
        });
      } else {
        // Enable clickable items on the page
        document.querySelectorAll(".clickable-item").forEach((item) => {
          item.classList.remove("disabled");
        });
      }
    });
  }

  private _loginSubscription: Subscription;
  private _principalSubscription: Subscription;
  ngOnDestroy() {
    if (this._loginSubscription) {
      this._loginSubscription.unsubscribe();
    }
    if (this._principalSubscription) {
      this._principalSubscription.unsubscribe();
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
    this.showCuratorMenu = this.headerSrv.showCuratorsMenu;
  }

  makeVisible(): string {
    if (this.headerSrv.hasFilterEnabled) {
      return "block";
    }
    if (this.ui.screenSizeIsLessOrEqual("md") && !this.isMinimal) {
      return "block";
    }
    return "none";
  }

  getWidth(): string | undefined {
    var homepageUserInfo = document.getElementById("homepage-user-info");
    var width = 0;
    if (homepageUserInfo) {
      width = homepageUserInfo?.clientWidth / 2;
    }
    return window?.innerWidth / 2 - width + "px";
  }

  getWalkthroughLabel(): string {
    let isInScix;
    this.commonService.isInScix.subscribe((data) => {
      isInScix = data;
    });
    if (
      this.commonService.isInTdammApp() ||
      this.ui.screenSizeIsLessOrEqual("xs")
    ) {
      return "3 - ACRONYM FINDER";
    } else if (isInScix === "Publications") {
      return "4 - ACRONYM FINDER";
    } else {
      return "5 - ACRONYM FINDER";
    }
  }

  isHome(): boolean {
    if (
      this.router.url.startsWith("/home") ||
      this.router.url.startsWith("/ej-home")
    )
      return true;
    else return false;
  }

  isResults(): boolean {
    if (this.router.url.startsWith("/search")) return true;
    else return false;
  }

  isContactUs(): boolean {
    if (this.router.url.includes("/contact-us")) return true;
    else return false;
  }

  isHelp(): boolean {
    if (this.router.url.startsWith("/HelpPage")) return true;
    else return false;
  }

  getImageSrc(): string {
    return this.isResults()
      ? "assets/img/sde-home-icon.svg"
      : "assets/img/nasa-home-icon.svg";
  }

  getImageAlt(): string {
    return this.isResults() ? "SDE HOME" : "TDAMM HOME";
  }

  // Function Call for Acronym finder
  searchAcronym() {
    if (this.searchControl) {
      this.acronymSearchText = Utils.trim(this.searchControl.value);
      if (this.acronymSearchText.length < 2) {
        this.acronymData = [];
        return;
      } else {
        this.acronymData = [];
        // Make request body for acronym finder
        const request = {
          parameters: {
            text: this.acronymSearchText,
          },
        };

        //API call if query is present in the input field
        if (this.acronymSearchText) {
          this.configService.getConfig(request).subscribe((response: any) => {
            this.acronymData = response.datasets.acronyms.records;
          });
          this.$gaService.event(
            googleAnalyticsConstants.action.search,
            googleAnalyticsConstants.category.search,
            this.acronymSearchText,
            0,
            true,
            {
              app_name: this.commonService.getAppDetailsForGA().app_name,
              page: this.commonService.getAppDetailsForGA().resultsPage,
              url: this.router.url,
              search: JSON.stringify({
                search_term: this.acronymSearchText,
                search_scientific_focus:
                  this.commonService.selectedScientificFocus,
              }),
            }
          );
        } else {
          this.acronymData = [];
        }
      }
    }
  }

  clearAcronymInput() {
    this.searchControl?.setValue("");
    this.acronymData = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.form = this.formBuilder.group({
      "search-acronym": "",
    });
    this.searchControl = this.form.get("search-acronym");
  }

  handleOutsideClick() {
    if (this.isSciFocusDropdownActive) {
      this.dropdown.hide();
    }
    if (this.acronymSearchText !== "" && this.acronymData?.length > 0) {
      this.acronymSearchText = "";
      this.acronymData = [];
    }
  }

  dropdownToggled(isDropdownActive: any): void {
    this.isSciFocusDropdownActive = isDropdownActive;
  }

  selectScientificFocus(sciFocusName: string, dropdown: BsDropdownDirective) {
    this.setBackgroundAsPerFocusSelection(sciFocusName);
    this.setScientificFocus(sciFocusName);
    this.searchService.selectScope(this.currentSciFocus);
    dropdown.hide();
    this.commonService.trackSearchEventForGoogleAnalytics(this.currentSciFocus);
  }

  setScientificFocus(sciFocusName: string) {
    this.currentSciFocus = sciFocusName;
    this.commonService.selectedScientificFocus = sciFocusName;
    this.searchService.query.scope = sciFocusName;
    this.appService.scope = sciFocusName;
  }

  resetScientificFocus(sciFocusName: string) {
    this.setBackgroundAsPerFocusSelection(sciFocusName);
    this.setScientificFocus(sciFocusName);
    this.router.navigate(["home"]);
  }

  getIconForScientificFocus(currentSciFocus: string) {
    let item = this.sciFocusList.find(
      (element) => element.name === currentSciFocus
    );
    return item["icon"];
  }

  // Added in case back button is clicked so that the scope from the URL is retained and there is no inconsistent UX
  getScopeDetailsFromURL() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params?.query) {
        let item = this.sciFocusList.find(
          (element) => element.name === JSON.parse(params.query).scope
        );
        if (item) {
          this.currentSciFocus = item.name;
        } else {
          this.currentSciFocus = "All";
        }
        this.setScientificFocus(this.currentSciFocus);
      }
    });
  }

  openHelpAndSupportModalFromHomePage(currentPage: string) {
    // this.headerSrv._showFilters = false;

    let modalData = {
      source: this.source,
    };

    this.modalRef = this.modalService.show(SdeCommonHelpSupportModalComponent, {
      initialState: modalData,
    });

    this.commonService.updateCurrentPage(currentPage);

    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.sdeHelpAndSupport,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.commonService.getAppDetailsForGA().homePage,
        url: this.router.url,
      }
    );
  }

  detectHelpandSupportEvent(event: KeyboardEvent) {
    if (event) {
      if (this.isHome()) {
        this.openHelpAndSupportModalFromHomePage("home");
      } else {
        this.openHelpAndSupportModalFromResultsPage("results");
      }
    }
  }

  openHelpAndSupportModalFromResultsPage(currentPage: string) {
    // this.headerSrv._showFilters = false;

    let modalData = {
      source: this.source,
    };
    this.modalRef = this.modalService.show(SdeCommonHelpSupportModalComponent, {
      initialState: modalData,
    });
    this.commonService.updateCurrentPage(currentPage);
  }

  getAriaLabel(item: string): string {
    switch (item) {
      case "Earth Science":
        return "Look for search results across Earth Science focus area";
      case "Planetary Science":
        return "Look for search results across Planetary Science focus area";
      case "Astrophysics":
        return "Look for search results across Astrophysics focus area";
      case "Biological & Physical Sciences":
        return "Look for search results across Biological & Physical Sciences focus area";
      case "Heliophysics":
        return "Look for search results across Heliophysics focus area";
      case "All":
        return "Look for search results across all scientific focus areas";
      default:
        return "";
    }
  }

  setBackgroundAsPerFocusSelection(focus: string = "All") {
    let img: HTMLImageElement | undefined;
    if (!this.isHome() && focus === "All") {
      img = this.imgService.getImage("assets/img/navbar-all.png");
    } else if (!this.isHome() && focus.includes("Earth Science")) {
      img = this.imgService.getImage("assets/img/navbar-earth-science.png");
    } else if (!this.isHome() && focus.includes("Planetary Science")) {
      img = this.imgService.getImage("assets/img/navbar-planetary.png");
    } else if (!this.isHome() && focus.includes("Astrophysics")) {
      img = this.imgService.getImage("assets/img/navbar-astrophysics.png");
    } else if (
      !this.isHome() &&
      focus.includes("Biological & Physical Sciences")
    ) {
      img = this.imgService.getImage("assets/img/navbar-bio-physical.png");
    } else if (!this.isHome() && focus.includes("Heliophysics")) {
      img = this.imgService.getImage("assets/img/navbar-heliophysics.png");
    }

    if (img && img?.src) {
      this.sdeHeaderStyle = {
        "background-image": `url(${img?.src})`,
        "background-size": "cover",
        height: "137px",
      };
    } else {
      this.sdeHeaderStyle = {
        "background-image": "inherit",
        "background-size": "cover",
      };
    }
  }

  navigateToEjApp() {
    //google analytics service call
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.environmentalJustice,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.commonService.getAppDetailsForGA().homePage,
        url: window.location.href,
        route: this.router.url,
      }
    );
  }

  getEJAppRoute() {
    return window.location.origin + "/app/" + appConfig.ejApp + "/#/ej/home";
  }

  generateSrcForTDAMM() {
    if (this.isContactUs()) {
      return "assets/img/help.svg";
    }
    return "assets/img/help-navbar.svg";
  }

  getWalkthroughLabelForSearchFocus(): string {
    let isInScix;
    this.commonService.isInScix.subscribe((data) => {
      isInScix = data;
    });
    if (isInScix === "Publications") {
      return "3 - SEARCH FOCUS";
    } else {
      return "4 - SEARCH FOCUS";
    }
  }
}
