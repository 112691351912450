<div class="container-landscape">
  <div class="left-half-landscape-container">
    <img
      id="logo"
      src="assets/img/nasa-meatball.svg"
      alt="nasa meatball"
      width="80px"
    />

    <div class="sde-text-landscape font-family-rajdhani-regular">
      <p>Science Discovery Engine</p>
    </div>
  </div>
  <div class="right-half-landscape-container">
    <div class="px-2 inside-div">
      <div class="landscape-view-text font-family-source-sans-pro-regular">
        <span> For optimal experience, please switch to Portrait Mode. </span>
      </div>
    </div>
  </div>
</div>
