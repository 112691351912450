<div class="d-flex flex-row align-items-stretch m-0 h-100">
  <!-- Left panel (navigation, extracts and entities) -->
  <div
    class="d-flex flex-column align-items-stretch border-right p-0"
    style="background: #fafbfd"
    [ngClass]="{ 'col-sm-5 col-md-4 col-lg-3': !collapsedPanel }"
    *ngIf="ui.screenSizeIsGreater('xs')"
  >
    <!-- Navigation buttons/links (template below) -->
    <ng-container *ngTemplateOutlet="nav"></ng-container>

    <div
      class="border-top border-bottom navigation navbar-sde-tabs"
      *ngIf="!collapsedPanel"
    >
      <!-- Navigation between the active sub panels -->
      <ul
        class="nav small"
        style="padding: 0 px; margin: 0 px"
        role="presentation"
      >
        <li
          class="nav-item"
          *ngFor="let panel of subpanels"
          [ngClass]="{
            'nav-item-related-docs-metadata':
              subpanel === 'relateddocumentsmetadata'
          }"
        >
          <a
            role="tab"
            class="nav-link"
            href="#"
            [ngClass]="{ active: subpanel === panel }"
            (click)="subpanel = panel; (false)"
          >
            {{ "msg#preview." + panel | sqMessage }}
          </a>
        </li>
      </ul>
    </div>

    <!-- Expanded panel different subpanels available -->
    <!-- Extracts/Pages panel -->
    <app-sde-preview-extracts-panel
      *ngIf="
        previewData &&
        subpanel === 'extracts' &&
        !pagesResults &&
        !collapsedPanel
      "
      class="d-flex flex-column flex-grow-1"
      class="flex-scroll"
      [previewData]="previewData"
      [preview]="preview"
      [extractsNumber]="10"
      [highlights]="previewHighlights"
      [type]="extractsType"
    >
    </app-sde-preview-extracts-panel>

    <div *ngIf="subpanel !== 'extracts' && !collapsedPanel" class="subpanel">
      <!-- Entities panel -->
      <div *ngIf="previewData && subpanel === 'entities'" class="p-2">
        <app-sde-preview-entity-panel
          [previewData]="previewData"
          [appName]="commonService.isInTdammApp() ? 'TDAMM' : 'SDE'"
          [preview]="preview"
          [style]="isDark() ? 'dark' : 'light'"
          (facetChecked)="entitiesChecked($event)"
        >
        </app-sde-preview-entity-panel>
      </div>
      <!-- Properties panel -->

      <!-- Related Documents -->
      <div
        *ngIf="
          previewData &&
          (subpanel === 'relateddocuments' ||
            subpanel === 'relateddocumentsmetadata')
        "
      >
        <app-sde-preview-related-docs-panel
          [previewData]="previewData"
          [query]="query"
          [style]="isDark() ? 'dark' : 'light'"
          class="d-flex flex-column flex-grow-1"
        >
        </app-sde-preview-related-docs-panel>
      </div>
    </div>
  </div>

  <!-- Right panel (iframe with preview HTML) -->
  <div class="container-fluid">
    <div class="first-div">
      <div class="preview-header-icons">
        <div class="icon-group">
          <!-- Highlight entities and extracts is not available for full page preview #665 -->
          <!-- <img src="assets/img/highlight.png" class="preview-icon" alt="entities_highlight" />
          <img src="assets/img/extracts.png" class="preview-icon" alt="extracts_highlight" /> -->
          <img
            tabindex="0"
            *ngIf="
              previewData &&
              previewData.record &&
              previewData.record['download_url']
            "
            [sqTooltip]="'msg#general.openOriginalDocument' | sqMessage"
            src="assets/img/source-doc-1.svg"
            (click)="goToSourceDoc()"
            class="preview-icon"
            alt="Open the original document from source link"
          />
          <img
            tabindex="0"
            *ngIf="
              previewData &&
              previewData.record &&
              previewData.record.docformat == 'pdf' &&
              previewData.record['download_url'] != ''
            "
            [sqTooltip]="'msg#general.downloadDocument' | sqMessage"
            src="assets/img/download.svg"
            (click)="downloadDocument(previewData?.record)"
            class="preview-icon"
            alt="Click to download the document"
          />

          <img
            tabindex="0"
            [sqTooltip]="'msg#general.copyLinkToDocument' | sqMessage"
            src="assets/img/copy.svg"
            (click)="copyURL()"
            class="preview-icon"
            alt="Copy link to the document"
          />
          <img
            *ngIf="ui.screenSizeIsLessOrEqual('xs')"
            src="assets/img/close.svg"
            (click)="navigateToSdeResults()"
            class="preview-icon"
            alt="close"
          />
        </div>
      </div>
    </div>
    <div
      id="large-preview"
      class="d-flex flex-shrink-1 flex-grow-1 flex-column preview-bg"
    >
      <!-- METADATA VIEWER -->
      <ng-container
        *ngIf="
          previewData &&
            previewData.record &&
            previewData.record['isMetadataViewer'];
          else docViewer
        "
      >
        <app-common-sde-metadata-viewer
          [previewData]="previewData"
          [previewType]="'expanded'"
          (dataLoadedEvent)="stopLoader($event)"
        ></app-common-sde-metadata-viewer>
      </ng-container>

      <!-- DEFAULT VIEWER  -->
      <ng-template #docViewer>
        <div
          *ngIf="previewData"
          class="p-2 preview-title font-family-rajdhani-semibold"
        >
          <a
            *ngIf="
              previewData &&
              previewData?.record &&
              previewData?.record.download_url &&
              previewData?.record.download_url !== ''
            "
            target="_blank"
            (click)="goToSourceDoc()"
            >{{ previewData?.record.title }}</a
          >
          <h3
            *ngIf="
              (previewData &&
                previewData?.record &&
                previewData?.record.download_url &&
                previewData?.record.download_url === '') ||
              !previewData?.record.download_url
            "
          >
            {{ previewData?.record.title }}
          </h3>
          <!-- Link to the original document -->
          <!-- <a
            *ngIf="getOriginalDocUrl() as url"
            [sqTooltip]="'msg#general.openOriginalDocument' | sqMessage"
            target="_blank"
            href="{{ url }}"
            (click)="notifyOriginalDoc()"
          >
            {{ url }}
          </a> -->
        </div>
        <sq-loading-bar [active]="loading"></sq-loading-bar>
        <div class="flex-grow-1 flex-shrink-1 overflow-hidden">
          <app-sde-preview
            *ngIf="showPreview"
            #sdePreview
            [previewType]="'FULL'"
            [id]="id"
            [query]="query"
            [highlightColors]="previewHighlights"
            [highlightEntities]="false"
            [preferenceName]="'mini-preview'"
            [scale]="0.9"
            (ready)="onPreviewReady()"
          >
          </app-sde-preview>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #nav>
  <div
    class="align-self-stretch d-flex p-2 preview-options-bar"
    [ngClass]="{
      'flex-column flex-grow-1 justify-content-start align-items-start':
        collapsedPanel,
      'justify-content-between align-items-center': !collapsedPanel
    }"
  >
    <div>
      <!-- Back button (go back to /search route) -->
      <button
        class="back-to-home"
        [placement]="leftPanelTooltipPlacement()"
        sqTooltip="{{ 'msg#facet.preview.searchPage' | sqMessage }}"
        (click)="backToSearchPage()"
      >
        <i class="fa fa-chevron-left" style="margin-right: 4px"></i>
        <span *ngIf="!collapsedPanel">BACK</span>
      </button>
    </div>
  </div>
</ng-template>
