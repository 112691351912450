<div #extractsMain>
  <ng-container *ngIf="previewData && !loading">
    <div
      class="text-center font-family-rajdhani-semibold py-3 no-data"
      *ngIf="extracts?.length === 0; else extractsTpl"
    >
      <span>{{ "msg#preview.noextract" | sqMessage }}</span>
    </div>

    <ng-template #extractsTpl>
      <div class="d-flex flex-row-reverse px-3 py-2">
        <div
          class="w-100 d-flex align-items-center justify-content-start ps-0 pe-4 py-1"
          *ngIf="showPagination"
        >
          <button
            class="me-2 btn btn-light left-extracts prev-button"
            (click)="previousExtract()"
            [disabled]="currentIndex < 1"
          >
            <img src="assets/img/previous.svg" class="prev-icon" />
          </button>
          <button
            class="ms-2 btn btn-light float-right-extracts next-button"
            (click)="nextExtract()"
            [disabled]="currentIndex >= extracts.length - 1"
          >
            <img src="assets/img/next.svg" class="next-icon" />
          </button>
        </div>
      </div>

      <div #virtualScrollBox>
        <sq-virtual-scroller
          class="flex-grow-1 overflow-auto mb-4 list-group"
          id="extracts-list"
          [list]="extracts"
          [itemsNumber]="extractsNumber"
          [scrollIndex]="scrollIndex"
          (newList)="displayedExtracts = $event"
        >
          <div
            *ngFor="let extract of displayedExtracts; let i = index"
            class="px-2 px-lg-3"
            [sqScrollIntoView]="{ active: i === currentIndex, first: i === 0 }"
            [id]="'extract-' + extract.relevanceIndex"
          >
            <a
              role="button"
              class="my-2 list-group-item list-group-item-action"
              [ngClass]="{ active: currentIndex === i }"
              (click)="scrollExtract(extract)"
            >
              <p
                class="py-2 px-2 extracts-box m-0 font-family-public-sans-regular"
                [innerHTML]="extract.text"
              ></p>
            </a>
          </div>
        </sq-virtual-scroller>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="extracts?.length > 0">
    <div class="legends-main-container">
      <div class="legends font-family-rajdhani-semibold">Legends</div>
      <div class="legends-main-div">
        <div class="legends-item">
          <div class="legends-box"></div>
          <div class="legends-text">Extracts</div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <ng-template #loadingTpl>
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="spinner-grow" role="status"></div>
      </div>
    </ng-template>
  </ng-container>
</div>
