import { Component, OnInit } from "@angular/core";
import { FiltersViewComponent } from "@sinequa/components/filters/filters-view.component";
import { UIService } from "@sinequa/components/utils";
import { CommonService } from "../../app/services/common.service";

@Component({
  selector: "app-sde-applied-filters",
  templateUrl: "./sde-applied-filters.component.html",
  styleUrls: ["./sde-applied-filters.component.scss"],
})
export class SdeAppliedFiltersComponent
  extends FiltersViewComponent
  implements OnInit
{
  constructor(private commonService: CommonService, public ui: UIService) {
    super();
  }

  ngOnInit(): void {}

  closeFilterPopup() {
    this.commonService.modalRef?.hide();
  }
}
