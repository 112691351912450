import { Component, Input } from "@angular/core";
import { PreviewEntityPanelComponent } from "@sinequa/components/preview/preview-entity-panel/preview-entity-panel.component";
@Component({
  selector: "app-sde-preview-entity-panel",
  templateUrl: "./sde-preview-entity-panel.component.html",
  styleUrls: ["./sde-preview-entity-panel.component.scss"],
})
export class SdePreviewEntityPanelComponent extends PreviewEntityPanelComponent {
  sortStates: boolean[] = [];
  selectAllStates: boolean[] = [];
  noEntities: boolean = false;
  allSelected: any;

  @Input() appName: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.entities && this.entities.length > 0) {
      this.entities.forEach(() => {
        this.sortStates.push(true);
        this.selectAllStates.push(false);
      });
    } else {
      this.noEntities = true;
    }
  }

  override ngOnChanges() {
    // if (this.entities && !!this.previewData) {
    //   // If the list of entities is provided as input
    //   this._entities = this.entities;
    // } else if (this.previewData) {
    //   // The list of entities is deduced from the preview data
    //   this._entities = this.generateEntitiesArray(
    //     this.previewData.highlightsPerCategory
    //   );
    // } else {
    //   // No entity to show
    //   this._entities = [];
    // }
    if (!this.previewData) {
      this.entities = undefined;
      return;
    }

    this.entities = Object.entries(this.previewData.highlightsPerCategory)
      .filter(
        ([type, data]) => !this.ignored.includes(type) && data.values?.length
      )
      .map(([type, data]) => ({
        type,
        values: data.values,
        icon: "sq-icon-" + type,
        display: data.categoryDisplayLabelPlural || data.categoryDisplayLabel,
        highlights: this.highlights?.find((hl) => hl.name === type),
      }));
  }

  changeSortingOrder(index: number) {
    this.sortStates[index] = !this.sortStates[index];
  }

  changeSelectAllState(index: number) {
    let checkedState = this.selectAllStates;
    checkedState[index] = !checkedState[index];
    this.selectAllStates = [...checkedState];
  }

  generateEntitiesArray(highlightData: any): string[] {
    let keys: any[] = Object.keys(highlightData);
    let smdKeys: string[] = [];
    keys.forEach((key) => {
      if (key.includes("sMD")) {
        smdKeys.push(key);
      }
    });
    keys = [
      ...keys.filter((element) => !element.includes("sMD")),
      ...smdKeys.reverse(),
    ];
    return keys.filter((value) => !this.ignored.includes(value));
  }
}
