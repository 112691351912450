import { Component, OnInit } from "@angular/core";
import { SearchService } from "@sinequa/components/search";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-sde-filters-modal",
  templateUrl: "./sde-filters-modal.component.html",
  styleUrls: ["./sde-filters-modal.component.scss"],
})
export class SdeFiltersModalComponent implements OnInit {
  constructor(
    protected searchService: SearchService,
    protected modalRef: BsModalRef
  ) {}

  ngOnInit(): void {}

  clearFilters() {
    delete this.searchService.query.filters;
    this.searchService.search();
    this.modalRef?.hide();
  }
}
