import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Action } from "@sinequa/components/action";
import { UserPreferences } from "@sinequa/components/user-settings";
import { UIService } from "@sinequa/components/utils";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import googleAnalyticsConstants from "../../analytics/google-analytics.constant";
import { SdeRecord } from "../../model/extended.interface";
import { CommonService } from "../../services/common.service";
import { BsFacetCard } from "./../../../../../components/facet/bootstrap/facet-card/facet-card";
import { environment } from "./../../../environments/environment";

@Component({
  selector: "app-sde-facet-card",
  templateUrl: "./sde-facet-card.component.html",
  styleUrls: ["./sde-facet-card.component.scss"],
})
export class SdeFacetCardComponent extends BsFacetCard implements OnInit {
  collapseActionNew: Action;
  entitiesFlag: boolean = true;
  extractsFlag: boolean = true;
  sortState: boolean = false;
  selectAllState: boolean = false;
  isChecked: boolean = true;

  previewOptions: any[];
  @Input() type: string = "default";
  @Input() record: SdeRecord;
  @Output() closeDocument: EventEmitter<any> = new EventEmitter();
  @Output() expandPreview: EventEmitter<any> = new EventEmitter();
  @Output() toggleEntities: EventEmitter<any> = new EventEmitter();
  @Output() toggleExtracts: EventEmitter<any> = new EventEmitter();
  @Output() copyLinkToDocument: EventEmitter<any> = new EventEmitter();
  @Output() shareDocument: EventEmitter<any> = new EventEmitter();
  @Output() downloadDocument: EventEmitter<any> = new EventEmitter();
  @Output() toggleSortEvent: EventEmitter<any> = new EventEmitter();
  @Output() toggleSelectAll: EventEmitter<any> = new EventEmitter();
  isAllSelected: any;

  constructor(
    public ui: UIService,
    public override prefs: UserPreferences,
    private commonService: CommonService,
    private router: Router,
    private $gaService: GoogleAnalyticsService
  ) {
    super(prefs);
    this.collapseActionNew = new Action({
      titlePlacement: this.defaultTooltipPlacement,
      fallbackPlacements: this.defaultTooltipFallbackPlacements,
      action: (action, event) => {
        // stop propagation to avoid the click outside event to be triggered
        event.stopPropagation();
        this._collapsed = !this._collapsed;
        this.facetCollapsed.next(this._collapsed ? "collapsed" : "expanded");
        if (!!this.facetComponent) {
          this.facetComponent.onCollapse(this._collapsed);
        }
        action.update();
      },
      updater: (action) => {
        action.icon = this._collapsed
          ? "fa-regular fa-plus"
          : "fa-regular fa-minus";
        action.title = this._collapsed
          ? "msg#facetCard.expand"
          : "msg#facetCard.collapse";
        this.updateActions();
      },
    });
  }

  override ngOnChanges(): void {
    this.updateActions();
    this.updateClickOutside();
    this.previewOptions = [
      {
        tooltip: "msg#general.toggleEntities",
        function: () => {
          this.entitiesFlag = !this.entitiesFlag;
          this.toggleEntities.next(this.entitiesFlag);
          this.$gaService.event(
            googleAnalyticsConstants.action.click,
            googleAnalyticsConstants.category.button,
            googleAnalyticsConstants.label.toggleEntities,
            0,
            true,
            {
              app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
              page: this.commonService.isInTdammApp()
                ? googleAnalyticsConstants.currentPage.tdammResultsPage
                : googleAnalyticsConstants.currentPage.sdeResultsPage,
              url: this.router.url,
              debug_mode: environment.googleAnalyticsDebugMode,

              ...this.commonService.createDocumentEventDetailsObject(
                this.record.title,
                this.record.collection,
                this.record.treepath,
                this.record.id
              ),
            }
          );
        },
        imageSrc: "assets/img/highlight.svg",
        hide: false,
        alt: "Toggle entities",
      },
      {
        tooltip: "msg#general.toggleExtracts",
        function: () => {
          this.extractsFlag = !this.extractsFlag;
          this.toggleExtracts.next(this.extractsFlag);
          this.$gaService.event(
            googleAnalyticsConstants.action.click,
            googleAnalyticsConstants.category.button,
            googleAnalyticsConstants.label.toggleRelevantExtracts,
            0,
            true,
            {
              app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
              page: this.commonService.isInTdammApp()
                ? googleAnalyticsConstants.currentPage.tdammResultsPage
                : googleAnalyticsConstants.currentPage.sdeResultsPage,
              url: this.router.url,
              debug_mode: environment.googleAnalyticsDebugMode,

              ...this.commonService.createDocumentEventDetailsObject(
                this.record.title,
                this.record.collection,
                this.record.treepath,
                this.record.id
              ),
            }
          );
        },
        imageSrc: "assets/img/extracts.svg",
        hide: false,
        alt: "Toggle relevant extracts",
      },
      {
        tooltip: "msg#general.openOriginalDocument",
        function: () => {
          window.open(this.record.download_url, "_blank");
          this.$gaService.event(
            googleAnalyticsConstants.action.click,
            googleAnalyticsConstants.category.button,
            googleAnalyticsConstants.label.openOrigDocumentFromSource,
            0,
            true,
            {
              app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
              page: this.commonService.isInTdammApp()
                ? googleAnalyticsConstants.currentPage.tdammResultsPage
                : googleAnalyticsConstants.currentPage.sdeResultsPage,
              url: this.router.url,
              debug_mode: environment.googleAnalyticsDebugMode,

              ...this.commonService.createDocumentEventDetailsObject(
                this.record.title,
                this.record.collection,
                this.record.treepath,
                this.record.id
              ),
            }
          );
        },
        imageSrc: "assets/img/source-doc-1.svg",
        hide: this.checkIfGoToSourceAvailable(),
        alt: "Open the original document from source link",
      },
      {
        tooltip: "msg#general.openInDocumentNavigator",
        function: () => {
          this.expandPreview.next(true);
          this.$gaService.event(
            googleAnalyticsConstants.action.click,
            googleAnalyticsConstants.category.button,
            googleAnalyticsConstants.label.openDocumentNavigator,
            0,
            true,
            {
              app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
              page: this.commonService.isInTdammApp()
                ? googleAnalyticsConstants.currentPage.tdammResultsPage
                : googleAnalyticsConstants.currentPage.sdeResultsPage,
              url: this.router.url,
              debug_mode: environment.googleAnalyticsDebugMode,

              ...this.commonService.createDocumentEventDetailsObject(
                this.record.title,
                this.record.collection,
                this.record.treepath,
                this.record.id,
                undefined,
                undefined
              ),
            }
          );
        },
        imageSrc: "assets/img/expand.svg",
        hide: this.ui.screenSizeIsLessOrEqual("sm"), // Do not show document navigator button in preview on mobile
        alt: "Open document in full screen mode in document navigator",
      },
      {
        tooltip: "msg#general.downloadDocument",
        function: () => {
          this.downloadDocument.emit();
          this.$gaService.event(
            googleAnalyticsConstants.action.click,
            googleAnalyticsConstants.category.button,
            googleAnalyticsConstants.label.downloadDocument,
            0,
            true,
            {
              app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
              page: this.commonService.isInTdammApp()
                ? googleAnalyticsConstants.currentPage.tdammResultsPage
                : googleAnalyticsConstants.currentPage.sdeResultsPage,
              url: this.router.url,
              debug_mode: environment.googleAnalyticsDebugMode,

              ...this.commonService.createDocumentEventDetailsObject(
                this.record.title,
                this.record.collection,
                this.record.treepath,
                this.record.id,
                this.record.download_url
              ),
            }
          );
        },
        imageSrc: "assets/img/download.svg",
        hide: this.checkIfDownloadAvailable(),
        alt: "Click to download the document",
      },
      {
        tooltip: "msg#general.copyLinkToDocument",
        function: () => {
          this.copyLinkToDocument.emit();
          this.$gaService.event(
            googleAnalyticsConstants.action.click,
            googleAnalyticsConstants.category.button,
            googleAnalyticsConstants.label.copyDocumentLink,
            0,
            true,
            {
              app_name: this.commonService.isInTdammApp() ? "TDAMM" : "SDE",
              page: this.commonService.isInTdammApp()
                ? googleAnalyticsConstants.currentPage.tdammResultsPage
                : googleAnalyticsConstants.currentPage.sdeResultsPage,
              url: this.router.url,
              debug_mode: environment.googleAnalyticsDebugMode,

              ...this.commonService.createDocumentEventDetailsObject(
                this.record.title,
                this.record.collection,
                this.record.treepath,
                this.record.id,
                undefined,
                "copied link"
              ),
            }
          );
        },
        imageSrc: "assets/img/copy.svg",
        hide: false,
        alt: "Copy link to the document",
      },
      {
        tooltip: "msg#general.closeDocumentPreview",
        function: () => {
          this.closeDocument.next(true);
        },
        imageSrc: "assets/img/close.svg",
        hide: false,
        alt: "Close preview mode",
      },
    ];
    this.updateActions();
  }

  public override getActions(): Action[] {
    if (this.hideActions) return [this.collapseActionNew]; // Hide other actions if collapsed
    const actions = [] as Action[];
    if (this.actionsFirst) {
      actions.push(...this.actions);
    }
    if (!this.viewActionsAreSecondary && this.viewActions?.length > 1) {
      actions.push(...this.viewActions);
    }
    if (this.facetComponent && !this.facetActionsAreSecondary)
      actions.push(...this.facetComponent.actions);
    if (
      this.settingsTpl ||
      (this.facetComponent?.settingsTpl && !this.facetActionsAreSecondary)
    )
      actions.push(this.settingsAction);
    if (this.expandable) actions.push(this.expandAction);
    if (this.collapsible) actions.push(this.collapseActionNew);
    if (!this.actionsFirst) {
      actions.push(...this.actions);
    }
    return actions.map((action) => {
      action.titlePlacement =
        action.titlePlacement || this.defaultTooltipPlacement;
      action.fallbackPlacements =
        action.fallbackPlacements || this.defaultTooltipFallbackPlacements;
      return action;
    });
  }

  override ngOnInit(): void {
    this.isChecked = true;

    this._collapsed = this.startCollapsed;
    this._expanded = this.startExpanded;
    this._settingsOpened = this.startSettingsOpened;

    this.collapseActionNew.update();
    this.expandAction.update();
    this.settingsAction.update();
    this.updateActions(); // Note that OnInit is called AFTER the 1st OnChanges
  }

  toggleSort() {
    this.sortState = !this.sortState;
    this.toggleSortEvent.next(this.sortState);
  }
  toggleSelect(event: any): void {
    this.isChecked = event.target.checked;
    this.toggleSelectAll.next(this.isChecked);
  }

  toggleExpand(event: any) {
    event.stopPropagation();
    this._collapsed = !this._collapsed;
    this.facetCollapsed.next(this._collapsed ? "collapsed" : "expanded");
    if (!!this.facetComponent) {
      this.facetComponent.onCollapse(this._collapsed);
    }
  }

  checkIfDownloadAvailable(): Boolean {
    if (
      this.record &&
      this.record.docformat == "pdf" &&
      this.record.download_url !== ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkIfGoToSourceAvailable(): Boolean {
    if (this.record && this.record.download_url) {
      return false;
    } else {
      return true;
    }
  }
}
