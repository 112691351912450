import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AlertsService } from "@sinequa/components/alerts";
import { BasketsService } from "@sinequa/components/baskets";
import { LabelsService } from "@sinequa/components/labels";
import { SavedQueriesService } from "@sinequa/components/saved-queries";
import { SelectionService } from "@sinequa/components/selection";
import { UserPreferences } from "@sinequa/components/user-settings";
import { AppService } from "@sinequa/core/app-utils";
import { ComponentWithLogin, LoginService } from "@sinequa/core/login";
import { JsonMethodPluginService } from "@sinequa/core/web-services";
import { Subscription } from "rxjs";

import { FEATURES } from "../config";
import { startConfig } from "./app.module";
import { CommonService } from "./services/common.service";
import { HeaderService } from "./services/header.service";

@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends ComponentWithLogin implements AfterViewInit {
  _isBackgroundVisible: boolean = true;

  fairUseText: string;

  routerSub: Subscription;

  mobileUserAgents: string[] = ["iPhone", "Android"];

  public _isPreview: boolean = false;

  globalLoader: boolean = false;

  constructor(
    // These 2 services are required by the constructor of ComponentWithLogin
    loginService: LoginService,
    cdRef: ChangeDetectorRef,
    // Services are instantiated by the app component,
    // to guarantee they are instantiated in a consistent order,
    // regardless of the entry route.
    // The order below impacts the order of the actions in the selection menu.
    prefs: UserPreferences,
    public savedQueriesService: SavedQueriesService,
    public basketsService: BasketsService,
    public alertsService: AlertsService,
    public labelsService: LabelsService,
    public selectionService: SelectionService,
    public appService: AppService,
    public http: HttpClient,
    public router: Router,
    public pluginService: JsonMethodPluginService,
    private hearderService: HeaderService,
    private commonService: CommonService,
    protected _location: Location,
    public activatedRoute: ActivatedRoute //private $gaService: GoogleAnalyticsService,
  ) {
    super();
    console.log(
      "startConfig information -> app:",
      startConfig.app,
      "production:",
      startConfig.production,
      "autoSAMLProvider:",
      startConfig.autoSAMLProvider
    );
    this.routerSub = router.events.subscribe((val: any) => {
      if (val)
        if (val.url?.includes("ej/home")) {
          this.unsubscribeAndNavigateToEj();
        }
      if (val.url?.includes("tdamm")) {
        this.unsubscribeAndNavigateToTDAMM();
      }
      let url: string = this.router.routerState.snapshot.url;

      this._isBackgroundVisible = false;
      this._isPreview = false;
      //this.sdeTitleService.setTitleBasedOnRoute();
      if (url.startsWith("/home")) {
        this._isBackgroundVisible = true;
      }
      if (url.startsWith("/crossPortalNpavigation")) {
        this._isBackgroundVisible = true;
      }
      if (url.startsWith("/preview")) {
        this._isPreview = true;
      }
      if (val instanceof NavigationEnd) {
        let item = localStorage.getItem("showWarning");
        if (!item) {
          this.showWarningOrNavigate();
        }
      }
    });

    this.commonService.globalNasaLoader.subscribe((isLoading) => {
      this.globalLoader = isLoading;
    });
  }

  override ngAfterViewInit(): void {
    this.loginService.login().subscribe((result: any) => {
      if (result.app && result.app.appLabel) {
        this.commonService.appLabel = result.app.appLabel;
      }
    });
  }

  unsubscribeAndNavigateToEj() {
    this.routerSub.unsubscribe();
    this.commonService.navigateToEjApplicationExistingTab();
  }

  unsubscribeAndNavigateToTDAMM() {
    this.routerSub.unsubscribe();
    this.commonService.navigateToTDAMMApplicationExistingTab();
  }

  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(event: any) {
    if (
      screen.orientation.type == "landscape-primary" ||
      screen.orientation.type == "landscape-secondary"
    ) {
      this.router.navigate(["landscape-view-message"]);
    } else if (screen.orientation.type == "portrait-primary") {
      this._location.back();
    }
  }

  // for checking whether the application is opened in mobile and tabs
  showWarningOrNavigate() {
    if (this.isMobileDevice(navigator.userAgent)) {
      localStorage.setItem("showWarning", "true");
      this.router.navigate(["mobile-view-message"], {});
    }
  }

  isMobileDevice(userAgentString: string): boolean {
    let item = this.mobileUserAgents.find((element) =>
      userAgentString.includes(element)
    );
    if (item) {
      return true;
    }
    return false;
  }

  /**
   * Function used by ngStyle to determine if the router-outlet content
   * should be scrollable or hidden
   */
  getOverflow(): string {
    let url: string = this.router.routerState.snapshot.url;
    // If we are currently on the home page, we want overflow to be hidden
    if (url.startsWith("/home")) {
      return "hidden";
    } else if (url.startsWith("/search")) {
      return "scroll";
    } else {
      // Default case is to scroll

      return "scroll";
    }
  }

  /**
   * Function used by template ngIf to determine if the action bar should be visible or hidden
   */
  displayActionBar() {
    let url: string = this.router.routerState.snapshot.url;
    // If we are currently on the preview page, we want action bar to be hidden
    return url.startsWith("/preview") ? false : true;
  }

  initDone: boolean = false;

  /**
   * Initialize the list of actions in the selection service.
   * This method may be called multiple times, before the login is actually complete,
   * hence the initDone and this.appService.app test
   */
  override onLoginComplete() {
    if (!this.initDone && this.appService.app) {
      this.initDone = true;
      let features = FEATURES;
      // The local config (config.ts) can be overriden by server-side config
      if (
        this.appService.app &&
        this.appService.app.data &&
        this.appService.app.data.features
      ) {
        features = <string[]>this.appService.app.data.features;
      }

      // Get the Acceptable Use Agreement text from file
      // Only show if the showCUIBanner flag is set to true
      if (this.hearderService.showCUIBanner)
        this.http
          .get("assets/fairUseAgreementText.txt", { responseType: "text" })
          .subscribe(
            (data) => {
              this.fairUseText = data;

              let todays_datetime = new Date().toISOString();
              let todays_date: string = todays_datetime.split("T")[0];

              // Check if the user has accepted the Acceptable Use Agreement that day

              if (
                !(
                  window.sessionStorage.getItem("lastAgreementDate") ===
                  todays_date
                )
              ) {
                // Prompt user with acceptable use agreement
                // window.alert(this.fairUseText);

                // Set 'lastAgreementDate' to today's date
                window.sessionStorage.setItem("lastAgreementDate", todays_date);
              }
            },
            (err) => {
              console.log("Error retrieving Acceptable Use Agreement");
              console.log(err);
            }
          );

      features.forEach((feature) => {
        switch (feature) {
          case "saved-queries": {
            this.selectionService.selectionActions.push(
              this.savedQueriesService.selectedRecordsAction
            );
            break;
          }
          case "baskets": {
            this.basketsService.selectedRecordsAction.icon = "fas fa-inbox"; // Overriding the baskets icon (hard coded in the service)
            this.selectionService.selectionActions.push(
              this.basketsService.selectedRecordsAction
            );
            break;
          }
          case "labels": {
            const action = this.labelsService.buildSelectionAction();
            if (action) {
              this.selectionService.selectionActions.push(action);
            }
            break;
          }
        }
      });
    }
  }
}
