<div class="modal-container applied-filters">
  <div class="modal-header filter-head">
    <div class="filter-heading">
      <h1
        class="modal-title pull-left filter-label font-family-rajdhani-regular"
      >
        FILTERS
      </h1>
      <div class="d-flex flex-row align-items-center justify-content-end">
        <span
          class="sde-filters-list sde-filter-button"
          role="button"
          [sqTooltip]="'msg#filters.clearTitle' | sqMessage"
          (click)="clearFilters()"
        >
          <span class="font-family-inter-bold">CLEAR ALL FILTERS</span>
        </span>
        <button type="button" class="filter-close-button" aria-label="Close">
          <img
            src="assets/img/filters-close-icon.svg"
            alt="Close filter popup"
            (click)="modalRef.hide()"
          />
        </button>
      </div>
    </div>

    <div class="filter-info font-family-public-sans-regular">
      These filters have been applied to your search results:
    </div>
  </div>
  <div class="modal-body">
    <app-sde-applied-filters
      *ngIf="searchService.query.filters"
      [query]="searchService.query"
      [closeOnClickOutside]="true"
      [advancedModeClass]="'position-absolute card border shadow p-3'"
      (filterEdit)="searchService.search()"
    >
    </app-sde-applied-filters>
  </div>
</div>
