export const appConfig = {
  sdeApp: "nasa-sba-smd",
  ejApp: "nasa-sba-ej",
  tdammApp: "nasa-sba-tdamm",
  feedbackURL: "https://sde-indexing-helper.nasa-impact.net/feedback/",
  googleAnalyticsDebugMode: true,
  sdeQueryService: "query-smd-primary",
  tdammQueryService: "tdamm_query_service",
  tdammPreviewService: "tdamm-preview-query",
  nasaGovURL: "https://www.nasa.gov/",
};
