<ng-template #headerTpl>
    <div class="d-flex flex-grow-1 align-items-center text-truncate">
        <div class="card-icon primary-icon fas fa-brain me-2" aria-hidden="true"></div>
        <div class="card-title text-truncate mb-0 py-1 me-auto">
            <span *ngIf="collapsed">
                {{'msg#machine-learning.answersCollapsed' | sqMessage:{values: {answersNb: answers.length} } }}
            </span>
            <span *ngIf="!collapsed">
                {{'msg#machine-learning.answers' | sqMessage }}
            </span>
        </div>
        <div class="card-action d-flex justify-content-center align-items-center mx-2" *ngIf="!collapsed">
            <button class="btn btn-sm btn-link" (click)="previous()" *ngIf="answers.length > 1">
                <i class="fas fa-chevron-left"></i>
            </button>
            {{'msg#machine-learning.answer' | sqMessage }} {{selectedAnswer+1}}/{{answers.length}}
            <button class="btn btn-sm btn-link" (click)="next()" *ngIf="answers.length > 1">
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>
    </div>
</ng-template>

<div class="card-body" (click)="openPreview(answer)" *ngIf="answer$ | async as answer">
    <div class="fw-bold">
        {{answer.text}}
        <div *ngIf="showLikeButtons" class="float-end">
            <button class="btn btn-link" (click)="$event.stopPropagation; likeAnswer(answer)"
                sqTooltip="This answer is helpful">
                <i class="far fa-thumbs-up" [ngClass]="{fas: answer.$liked}"></i>
            </button>
            <button class="btn btn-link" (click)="$event.stopPropagation; dislikeAnswer(answer)"
                sqTooltip="This answer is wrong">
                <i class="far fa-thumbs-down" [ngClass]="{fas: answer.$liked === false}"></i>
            </button>
        </div>
    </div>
    <div class="my-3 passage-text">
        <span *ngIf="answer.$record?.modified && !hideDate" class="extracts-date">{{answer.$record.modified | sqDate:dateFormat }} - </span>
        <span [innerHTML]="answer.passage?.highlightedText"></span>
    </div>
    <ng-container *ngIf="answer.$record as record">
        <sq-result-title [record]="record" titleLinkBehavior="action" (titleClicked)="onTitleClicked($event, answer)"
            class="d-block text-truncate"></sq-result-title>
        <sq-result-source [record]="record" [displayTreepath]="true" (click)="$event.stopPropagation();">
        </sq-result-source>
    </ng-container>
</div>