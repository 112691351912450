import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { FacetService } from "@sinequa/components/facet";
import { SearchService } from "@sinequa/components/search";
import { SearchFormComponent } from "@sinequa/components/search-form/search-form.component";
import { UserPreferences } from "@sinequa/components/user-settings";
import { UIService, VoiceRecognitionService } from "@sinequa/components/utils";
import { AppService } from "@sinequa/core/app-utils";
import { LoginService } from "@sinequa/core/login";
import { CommonService } from "./../../services/common.service";

@Component({
  selector: "app-sde-search-form",
  templateUrl: "./sde-search-form.component.html",
  styleUrls: ["./sde-search-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdeSearchFormComponent
  extends SearchFormComponent
  implements OnInit
{
  constructor(
    public override searchService: SearchService,
    public override facetService: FacetService,
    public override loginService: LoginService,
    public override appService: AppService,
    public override voiceService: VoiceRecognitionService,
    public override prefs: UserPreferences,
    public override cdRef: ChangeDetectorRef,
    public commonService: CommonService,
    protected ui: UIService
  ) {
    super(
      searchService,
      facetService,
      loginService,
      appService,
      voiceService,
      prefs,
      cdRef
    );
  }

  // override ngOnInit(): void {}

  override ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  override applyFilters(collapse = true) {
    // Determine if the text has changed compared to the input query
    const isTextSearch =
      (this.query.text || "") !== (this.editedQuery.text || "");

    let currentSciFocus;

    this.query.text = this.editedQuery.text;
    this.query.filters = this.editedQuery.filters;
    // this.query.scope = this.editedQuery.scope;
    // this.query.tab = "Data";

    if (this.commonService.selectedScientificFocus !== "") {
      currentSciFocus = this.commonService.selectedScientificFocus;
      this.query.scope = currentSciFocus;
      this.appService.scope = currentSciFocus;
      this.query.tab = "Data";
    } else {
      currentSciFocus = "All";
      this.query.scope = currentSciFocus;
      this.query.tab = "Data";
    }

    if (!this.query.text?.trim()) {
      delete this.query.text;
      delete this.query.sort; // Prevent 500 error if no text but orderby text
    }

    delete this.query.basket; // If a basket was open, we want to go back to a regular search
    delete this.query.page; // If pagination occurred, we want to reset that

    if (this.appService.isNeural()) {
      this.commonService.enableNeuralSearch$.subscribe((enabled) => {
        this.enableNeuralSearch = enabled;
        // (Un)Set the query.neuralSearch flag
        if (this.enableNeuralSearch) {
          delete this.query.neuralSearch;
        } else {
          this.query.neuralSearch = false;
        }
      });
      // Add GA tracking
    }

    this.canApply = false; // Hide the hide button (if it was shown)

    if (!this.expanded !== collapse) {
      // Collapse
      this.expanded = !collapse;
      this.expandedEvent.emit(this.expanded);
    }
    this.commonService.showDidYouMean = null;
    this.search(isTextSearch); // Actually search
    // calling the google analytics method
    this.commonService.trackSearchEventForGoogleAnalytics(currentSciFocus);
  }

  getWalkthroughLabelForVoiceSearch() {
    let isInScix;
    this.commonService.isInScix.subscribe((data) => {
      isInScix = data;
    });
    if (
      this.commonService.isInTdammApp() ||
      isInScix === "Publications" ||
      this.ui.screenSizeIsLessOrEqual("xs")
    ) {
      return "2 - VOICE SEARCH";
    } else {
      return "2 - VOICE SEARCH";
    }
  }

  override clearForm() {
    if (this.canApply) {
      this.canApply = false;
      this.editedQuery.filters = this.query.filters;
    }
    delete this.editedQuery.text;
    // when strict refine is checked (admin panel), remove the queryId on clear
    delete this.query.queryId;
    this.query.text = "";
    // this.searchInput.nativeElement.focus();
    this.cdRef.detectChanges();

    if (this.searchService.query.tab == "Publications") {
      this.searchService.query.tab = "Data";
    }
    this.searchService.search();
  }
}
