import { Component, OnInit } from "@angular/core";
import { BsPager } from "@sinequa/components/search/bootstrap/pager/pager";
import { SearchService } from "@sinequa/components/search/search.service";

@Component({
  selector: "app-sde-pager",
  templateUrl: "./sde-pager.component.html",
  styleUrls: ["./sde-pager.component.scss"],
})
export class SdePagerComponent extends BsPager implements OnInit {
  constructor(protected override searchService: SearchService) {
    super(searchService);
  }

  ngOnInit(): void {}

  getPageLabel(item, currentPage): string {
    switch (item) {
      case "«":
        return "Go to the first page.";
      case "‹":
        return "Go back to the previous page.";
      case "›":
        return "Go to the next page.";
      case "»":
        return "Go to the last page";
      case currentPage:
        return "You are on page number" + currentPage;
      case Number(item):
        return "Go to results on page number" + item;
      default:
        return "";
    }
  }

  navigateToResults(pageNo: number) {
    if (pageNo !== this.currentPage) {
      let resultPage = document.getElementById("results-page") as HTMLElement;
      resultPage.scrollIntoView({ behavior: "smooth" });
      document.body.scroll(0, 0);
      this.gotoPage(pageNo);
    }
  }

  onItemClick(event: MouseEvent, page: number, isDisabled: boolean) {
    if (isDisabled) {
      // if it is disabled
      event.preventDefault();
    } else {
      // for non-disabled
      this.navigateToResults(page);
    }
  }
}
