import { FacetConfig } from "@sinequa/components/facet";
import { PreviewHighlightColors } from "@sinequa/components/preview";

/**
 * This list is used by Vanilla Search to activate key features in the UI.
 * The order below determines the order of menus, buttons, facets, etc.
 */
export const FEATURES: string[] = [
  "saved-queries",
  "recent-documents",
  "recent-queries",
  "baskets",
  "labels",
  "alerts",
  "suggests",
];

export const FACETS: FacetConfig<{}>[] = [
  {
    name: "geo",
    title: "msg#facet.geo.title",
    type: "list",
    icon: "fas fa-globe-americas",
    aggregation: "Geo",
    parameters: {
      showCount: true,
      searchable: true,
      allowExclude: true,
      allowOr: true,
      allowAnd: false,
      displayEmptyDistributionIntervals: false,
    },
  },
  {
    name: "company",
    title: "msg#facet.company.title",
    type: "list",
    icon: "fas fa-building",
    aggregation: "Company",
    parameters: {
      showCount: true,
      searchable: true,
      allowExclude: true,
      allowOr: true,
      allowAnd: false,
      displayEmptyDistributionIntervals: false,
    },
  },
  {
    name: "docformat",
    title: "msg#facet.docformat.title",
    type: "list",
    icon: "far fa-file-word",
    aggregation: "DocFormat",
    parameters: {
      showCount: true,
      searchable: true,
      allowExclude: true,
      allowOr: true,
      allowAnd: false,
      displayEmptyDistributionIntervals: false,
    },
  },
  {
    name: "modified",
    title: "msg#facet.modified.title",
    type: "list",
    icon: "fas fa-calendar-day",
    aggregation: "Modified",
    parameters: {
      showCount: true,
      searchable: true,
      allowExclude: true,
      allowOr: true,
      allowAnd: false,
      displayEmptyDistributionIntervals: false,
    },
  },
  {
    name: "size",
    title: "msg#facet.size.title",
    type: "list",
    icon: "fas fa-sort-amount-up-alt",
    aggregation: "Size",
    parameters: {
      showCount: true,
      searchable: true,
      allowExclude: true,
      allowOr: true,
      allowAnd: false,
      displayEmptyDistributionIntervals: false,
    },
  },
  {
    name: "documentlanguages",
    title: "msg#facet.documentlanguages.title",
    type: "list",
    icon: "far fa-comment",
    aggregation: "DocumentLanguages",
    parameters: {
      showCount: true,
      searchable: true,
      allowExclude: true,
      allowOr: true,
      allowAnd: false,
      displayEmptyDistributionIntervals: false,
    },
  },
  {
    name: "concepts",
    title: "msg#facet.concepts.title",
    type: "list",
    icon: "fas fa-comment-dots",
    aggregation: "Concepts",
    parameters: {
      showCount: true,
      searchable: true,
      allowExclude: true,
      allowOr: true,
      allowAnd: false,
      displayEmptyDistributionIntervals: false,
    },
  },
];

export const METADATA: string[] = [
  "authors",
  "docformat",
  "modified",
  "size",
  "treepath",
  "filename",
];

/**
 *    PATH URLS - Used in the application
 */
export const URLS = {
  RELATIVE_URLS: {
    curatorReportHomepage: "/plugin?plugin=CuratorHomepage",
    duplicateReportItem: "/plugin?plugin=DuplicateDocsHomepage#",
    piiReportItem: "/plugin?plugin=PIIReportHomepage",
    sensitiveReportItem: "/plugin?plugin=SensitiveDocs",
    performanceReportItem: "/plugin?plugin=SystPerfReport",
  },
  ABS_URLS: {
    giveFeedback: "https://www.surveymonkey.com/r/KD3Z5S3",
  },
};

export const PREVIEW_HIGHLIGHTS: PreviewHighlightColors[] = [
  {
    name: "company",
    color: "white",
    bgColor: "#FF7675",
  },
  {
    name: "geo",
    color: "white",
    bgColor: "#74B9FF",
  },
  {
    name: "person",
    color: "white",
    bgColor: "#00ABB5",
  },
  {
    name: "extractslocations",
    color: "black",
    bgColor: "#fffacd",
  },
  {
    name: "matchlocations",
    color: "black",
    bgColor: "#ff0",
  },
  {
    name: "sMDInstruments",
    color: "black",
    bgColor: "#6b963d",
  },
  {
    name: "sMDPlatforms",
    color: "black",
    bgColor: "#bdd09f",
  },
  {
    name: "sMDMissions",
    color: "black",
    bgColor: "#404f24",
  },
  {
    name: "tdammMessenger",
    color: "black",
    bgColor: "#c4d0d9",
  },
  {
    name: "tdammObject",
    color: "black",
    bgColor: "#c9c0cb",
  },
  {
    name: "tdammSignal",
    color: "black",
    bgColor: "#c9c7d9",
  },
];
