<ng-container *ngIf="previewData">
  <!--                     RELATED DOCUMENTS                                    -->
  <app-sde-facet-card
    *ngIf="!loading && similarNotDuplicates"
    [title]="showSimilarTitle()"
    [icon]="'fas fa-book'"
    [collapsible]="true"
    [startCollapsed]="false"
    class="d-block mt-2"
  >
    <app-sde-facet-list #facet [searchable]="false"></app-sde-facet-list>
    <div
      *ngFor="
        let item of similarNotDuplicates | slice : min_similar : max_similar
      "
    >
      <div class="facet-results-scrollable ng-star-inserted">
        <div
          class="position-relative list-group-item list-group-item-action border-0 px-3 py-1 facet-row cursor-pointer ng-star-inserted"
          title="{{ 'msg#preview.compareDocuments' | sqMessage }}"
        >
          <div class="d-flex justify-content-between align-items-baseline">
            <a
              class="text-truncate mr-auto"
              style="z-index: 1"
              title="{{ item.title }}"
              (click)="openPreview(item)"
              target="_blank"
            >
              <i
                class="{{ documentIconClass(item) }}"
                title="{{ item.fileext }}"
              ></i>
              {{ item.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!isOnlyOnePageSimilar"
      class="d-flex justify-content-center align-items-center"
      style="text-align: center"
    >
      <span style="width: 500px"
        ><button
          title="Previous"
          type="button"
          class="btn btn-outline-primary btn-sm"
          data-expands-id="box-content-relevant-results"
          style="margin-right: 10px; margin-top: 10px; font-size: small"
          (click)="showSimilarPrevious5Records()"
          [disabled]="disableSimilarPreviousButton"
        >
          &#60;&#60;
        </button>
        <span>{{ min_similar + 1 }} - {{ max_similar }}</span>
        <button
          title="Next"
          type="button"
          class="btn btn-outline-primary btn-sm"
          data-expands-id="box-content-relevant-results"
          style="margin-left: 10px; margin-top: 10px; font-size: small"
          (click)="showSimilarNext5Records()"
          [disabled]="disableSimilarNextButton"
        >
          &#62;&#62;
        </button>
      </span>
    </div>

    <!--                    SPINNER                                                 -->
    <div
      *ngIf="loading"
      class="d-flex justify-content-center align-items-center"
      style="height: 250px"
    >
      <div class="spinner-grow" role="status"></div>
    </div>

    <div class="text-center" *ngIf="!previewData">
      {{ "msg#preview.loading" | sqMessage }}
    </div>
  </app-sde-facet-card></ng-container
>
