
<div *ngIf="passages && documentsNb" class="text-end text-muted px-3 py-2">{{'msg#machine-learning.passagesFound' |
  sqMessage:{passagesNb: passages.length, documentsNb: documentsNb} }}
</div>

<ul class="list-group list-group-flush" *ngIf="passages">

  <li role="button" *ngFor="let passage of passages" (click)="openPreview(passage)" class="list-group-item list-group-item-action sq-passage">

    <div class="sq-passage-title d-flex align-content-center mb-1">
      <sq-result-title
        [record]="passage.$record"
        (titleClicked)="onTitleClicked($event, passage)"
        (click)="$event.stopPropagation()"
        class="d-block text-truncate">
      </sq-result-title>
    </div>

    <div class="sq-passage-text">
      <span *ngIf="passage.$record?.modified && !hideDate" class="extracts-date">
        {{passage.$record.modified | sqDate:dateFormat }} -
      </span>
      <span [innerHTML]="passage.highlightedText"></span>
    </div>

  </li>

</ul>
